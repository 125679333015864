

























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import Shared from '@/common/shared'
import { ImageType } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import CustomerAvailabilityPreview from '@/components/CustomerAvailabilityPreview.vue'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EditableHeader from '@/components/EditableHeader.vue'
import UpdateQuestionModeJobDetailModel from '@/models/claim/UpdateQuestionModeJobDetailModel'
import AddEmergencyAnswerModel from '@/models/claim/AddEmergencyAnswerModel'
import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ThanksDialog from '@/components/ThanksDialog.vue'

@Component({
  name: 'QuestionModePortal',
  components: {
    ThanksDialog,
    EmergencyQuestionGenerator,
    DateTimePicker,
    CustomerAvailabilityPreview,
    EditableHeader,
    MessageCard,
    ImageUpload,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class InsurerPortal extends Vue {
  private ready = false
  private isThanksDialogOpen = false
  private localTimer: Date = new Date()
  private localTimerForClaim: Date = new Date()
  private showCustomerAvailabilityStep = true //Question mode - True mean availability time slot at step 6 didn't selected, need show next btn at step 5 to go step 6. False mean time slot already selected, show submit btn at step 5 instead of next btn
  private isQuestionModeLinkNotValid = false
  private emergencyImageType: ImageType = ImageType.ExampleofEmergency

  $refs!: {
    questionModeWrapper: InstanceType<typeof ValidationObserver>
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get emergencies() {
    return this.insurerPortal.emergencies
  }

  private set emergencies(payload: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', payload)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.insurerPortal.customerAvailability
  }

  private set customerAvailability(payload: CustomerAvailabilityModel) {
    this.$store.dispatch('insurerPortalModule/setCustomerAvailability', payload)
  }

  public stepTitles: string[] = ['Explain', 'Customer Availability', 'Upload']

  public gaNextStepEvents = [
    { step: 1, event: 'Emergency Question Completed - Question mode' },
    { step: 2, event: 'Customer Availability - Question mode' },
    { step: 3, event: 'Image Upload Completed - Question mode' },
  ]

  public gaPreviousStepEvents = [
    { step: 2, event: 'Back To Emergency Question List - Question mode' },
  ]

  public get currentTabHref(): string {
    return `step${this.currentStep}`
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }
  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }
  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get clientName(): string {
    return this.$route.params.clientName
  }

  private get trackingIdParam(): string {
    return this.$route.params.trackingId
  }

  private handleLinkClicksInGA(
    categoryName: string,
    actionName: string,
    time: Date | null = null
  ) {
    if (this.appInEditMode) {
      // return if app is in edit mode
      return
    }
    // send event updates to google analytics
    Shared.sendEventsInGoogleAnalytics(
      categoryName,
      actionName,
      time ? time : this.localTimer
    )
    // reset timer for second button click
    this.localTimer = new Date()
  }

  // We take a param, incase we need to move forward
  // more than one step in the form, in the future
  public async nextStep(stepValue = 1) {
    if (!(await this.validateCurrentStep())) return

    const gaEvent = this.gaNextStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'NEXT')
    }

    this.$store.dispatch('generalModule/nextStep', stepValue)
    this.scrollToTop()
  }

  public previousStep(stepValue = 1) {
    const gaEvent = this.gaPreviousStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'PREVIOUS')
    }
    this.$store.dispatch('generalModule/previousStep', stepValue)
    this.scrollToTop()
  }

  public async validateCurrentStep(): Promise<boolean> {
    const isValid = await this.activeStepValidator.validate()
    if (!isValid) {
      for (const key of Object.keys(this.activeStepValidator.fields).sort()) {
        if (this.activeStepValidator.fields[key].invalid) {
          this.activeStepValidator.refs[key].$el.scrollIntoView()
          return false
        }
      }
    }
    return true
  }

  public get activeStepValidator() {
    return this.$refs.questionModeWrapper.observers[this.currentStep - 1]
  }

  private async created() {
    if (!this.getTrackingId) {
      this.$store.dispatch(
        'generalModule/updateTrackingId',
        this.trackingIdParam
      )
    }

    try {
      const { isAllLoggedEmergenciesAccepted } = await this.$store.dispatch(
        'insurerPortalModule/getQuestionModeJobDetails'
      )

      if (isAllLoggedEmergenciesAccepted) {
        this.$router.push({
          name: 'claimtracking',
          params: {
            trackingId: this.trackingIdParam,
            clientName: this.clientName,
          },
        })
        return
      }

      this.ready = true
      this.showCustomerAvailabilityStep = !(
        this.customerAvailability.timeSlot &&
        this.customerAvailability.timeSlot.length <= 0
      )
    } catch (error) {
      // TODO: handle error
      this.$router.replace('/')
      this.isQuestionModeLinkNotValid = true
    }
    this.handleLinkClicksInGA('Client Portal', 'LOADED')
  }

  private async updateClaimDetail() {
    const {
      healthAndSafetyQAs,
      vulnerabilityQA,
      accessNoteQA,
      emergenciesQAs,
    } = this.insurerPortal

    const addEmergencyAnswers = emergenciesQAs.map(
      ({ id, answer, comment }) =>
        ({ id, answer, comment } as AddEmergencyAnswerModel)
    )

    const updateQuestionModeJobDetail = new UpdateQuestionModeJobDetailModel()
    updateQuestionModeJobDetail.emergenciesQAs = addEmergencyAnswers
    updateQuestionModeJobDetail.healthAndSafetyQAs = healthAndSafetyQAs
    updateQuestionModeJobDetail.vulnerabilityQA = vulnerabilityQA
    updateQuestionModeJobDetail.accessNoteQA = accessNoteQA
    if (this.customerAvailability.id) {
      updateQuestionModeJobDetail.customerAvailability =
        this.customerAvailability
    } else {
      updateQuestionModeJobDetail.customerAvailability =
        new CustomerAvailabilityModel()
      updateQuestionModeJobDetail.customerAvailability.timeSlot =
        this.customerAvailability.timeSlot
    }
    const subDomain = window.location.host
    // Update Question Mode Job Detail
    this.isLoading = true

    try {
      const result = await this.$store.dispatch(
        'insurerPortalModule/updateQuestionModeJobDetails',
        {
          subDomain: subDomain,
          model: updateQuestionModeJobDetail,
        }
      )
      if (result) {
        this.handleLinkClicksInGA('Customer Availability Filled', 'NEXT')
        this.handleLinkClicksInGA(
          'Claim Logged',
          'CLAIM LOGGED',
          this.localTimerForClaim
        )
        this.localTimerForClaim = new Date()
        if (this.showCustomerAvailabilityStep) {
          this.nextStep()
        } else {
          this.nextStep(2)
        }
      }
      this.isLoading = false
    } catch (error) {
      // TODO: handle error
      this.isLoading = false
    }
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
