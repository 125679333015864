import TemplateCookieRecord from '../cookies/template-cookie-record'
import { IClientTemplate } from './interfaces/client-template'

export default class ClientTemplate implements IClientTemplate {
  public subDomain = ''
  public portalVersion = ''
  public primaryColor = ''
  public secondaryColor = ''
  public complementaryColor = ''
  public logoURL = ''
  public favIconURL = ''
  public headingStep1 = ''
  public descriptionStep1 = ''
  public headingStep2 = ''
  public descriptionStep2 = ''
  public headingStep3 = ''
  public descriptionStep3 = ''
  public headingStep4 = ''
  public descriptionStep4 = ''
  public headingStep5 = ''
  public descriptionStep5 = ''
  public headingStep6 = ''
  public descriptionStep6 = ''
  public headingStep7 = ''
  public descriptionStep7 = ''
  public fallBackStarPolicy = 0
  public policySchedules: number[] = []
  public topEmergencies: number[] = []
  public displayName = ''
  public privacyPolicyText = ''
  public legalNoticeText = ''
  public cookieNoticeText = ''
  public errorColor = ''
  public inputTextColor = ''
  public templateCookieRecord: TemplateCookieRecord = new TemplateCookieRecord()
}
