import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import store from '@/store'
import { ValidationRule } from 'vee-validate/dist/types/types'

export const recaptchaToken: ValidationRule = {
  validate: (recaptchaToken: any) => recaptchaToken !== null,
  message: () => 'Invalid user detected by Recaptcha',
}

export const recaptchaResponse: ValidationRule = {
  validate: () => {
    // Vee validate used to have a cool way to pass data into the message function,
    // as shown here https://codesandbox.io/s/3k649?file=/src/vee-validate.js
    // This no longer works, so now we have to re-access the message from vuex
    const recaptchaResponse: RecaptchaResponse | null =
      store.getters['generalModule/recaptchaResponse']
    return recaptchaResponse !== null && recaptchaResponse.success
  },
  message: () => {
    const recaptchaResponse: RecaptchaResponse | null =
      store.getters['generalModule/recaptchaResponse']
    return recaptchaResponse && recaptchaResponse.message !== null
      ? `Recaptcha Error: ${recaptchaResponse.message}`
      : 'Recaptcha Error'
  },
}
