import { render, staticRenderFns } from "./HealthSafetyQA.vue?vue&type=template&id=4585fd42&scoped=true&"
import script from "./HealthSafetyQA.vue?vue&type=script&lang=ts&"
export * from "./HealthSafetyQA.vue?vue&type=script&lang=ts&"
import style0 from "./HealthSafetyQA.vue?vue&type=style&index=0&id=4585fd42&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4585fd42",
  null
  
)

export default component.exports