






























































import { Component, Vue } from 'vue-property-decorator'
import { cookieService } from '@/services/cookie-service'
import CookieOptinModal from '@/components/cookie/CookieOptinModal.vue'
import DefaultCookieSettings from '@/models/cookies/settings/default-cookie-settings'
import Environment from '@/common/environment'
import VueGtag from 'vue-gtag'
import Shared from '@/common/shared'

@Component({
  components: {
    CookieOptinModal,
  },
})
export default class CookieBanner extends Vue {
  private cookieName = 'CET-Cookie-Optin'

  public get showCookieBanner(): boolean {
    return this.$store.getters['cookieModule/showCookieBanner']
  }

  public set showCookieBanner(showBanner: boolean) {
    this.$store.dispatch('cookieModule/submitShowCookieBanner', showBanner)
  }

  public get showOptinModal(): boolean {
    return this.$store.getters['cookieModule/showOptinModal']
  }

  public set showOptinModal(showModal: boolean) {
    this.$store.dispatch('cookieModule/submitShowOptinModal', showModal)
  }

  public get cookieConsent(): { [key: string]: boolean } {
    return this.$store.getters['cookieModule/cookieConsent']
  }

  public setCookieConsent(key: string, consentValue: boolean) {
    const consent = this.cookieConsent
    consent[key] = consentValue
    this.$store.dispatch('cookieModule/submitCookieConsent', consent)
  }

  private acceptAllConsent() {
    Object.keys(this.cookieConsent).forEach((key) =>
      this.setCookieConsent(key, true)
    )
    this.setTrackingOptions()
  }

  private setTrackingOptions() {
    if (this.cookieConsent.performanceConsent) {
      this.setPerformanceTracking()
    }
    this.hideCookieConsent()
    this.setCookie()
  }

  private setPerformanceTracking() {
    Vue.use(VueGtag, {
      config: { id: this.environment.googleAnalyticsKey },
    })
    Shared.setGtagInstance(this.$gtag)
  }

  private setCookie() {
    let cookieValue = ''
    Object.keys(this.cookieConsent).forEach((key) => {
      cookieValue += `${key}=${this.cookieConsent[key]}`
      // Make sure its not the last value, as we dont want to add the '&' to the final value.
      if (
        Object.keys(this.cookieConsent)[
          Object.keys(this.cookieConsent).length - 1
        ] !== key
      ) {
        cookieValue += '&'
      }
    })
    cookieService.setCookie(this.cookieName, cookieValue)
  }

  private retrieveCookie(): string | null {
    const cookies: string[] = document.cookie.split(';')
    const cetCookie = cookies.filter((x) => x.includes(this.cookieName))[0]
    return cetCookie ? cetCookie.substring(cetCookie.indexOf('=') + 1) : null
  }

  private setInitialCookieConsent(cookie: string) {
    const cookieOptins: string[] = cookie.split('&')

    cookieOptins.forEach((optin) => {
      const optinKey = optin.split(/=(.+)/)[0]
      const optinValue = optin.split(/=(.+)/)[1]
      this.setCookieConsent(optinKey, JSON.parse(optinValue))
    })
    this.setTrackingOptions()
  }

  private hideCookieConsent() {
    this.showCookieBanner = false
    this.showOptinModal = false
  }

  private get settings(): DefaultCookieSettings {
    return this.$store.getters['cookieModule/defaultCookieSettings']
  }

  private setupCookieConsentModal() {
    this.settings.cookieOptions.forEach((x) =>
      this.setCookieConsent(x.key, false)
    )
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private created() {
    this.setupCookieConsentModal()
    const cookie: string | null = this.retrieveCookie()

    if (cookie && cookie.length > 0) {
      this.setInitialCookieConsent(cookie)
      this.hideCookieConsent()
    }

    // Hide the banner on cookie policy page
    if (this.$router.currentRoute.path.includes('/cookienotice')) {
      this.showCookieBanner = false
    }
  }
}
