import PolicyHolderController from '@/api/policyHolderController'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import ClientTemplate from '@/models/client/client-template'
import EmergencyModel from '@/models/EmergencyModel'
import EmergencyQA from '@/models/EmergencyQA'
import SearchEmergencyQuestionModel from '@/models/SearchEmergencyQuestionModel'
import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { ActionTree } from 'vuex'

function mergeEmergencyQAsWithPreviousState(
  newValue: EmergencyQA[],
  stateValue: EmergencyQA[]
) {
  if (stateValue.length > 0) {
    const finalEmergenciesQAs = newValue.map((question: EmergencyQA) => {
      const result = question
      const index = stateValue.findIndex(
        (e) =>
          e.forEmergencyTypeId === question.forEmergencyTypeId &&
          e.forEmergencyDetailId === question.forEmergencyDetailId &&
          e.policyQuestionId === question.policyQuestionId
      )
      if (index > -1) {
        result.answer = stateValue[index].answer
        result.comment = stateValue[index].comment
      }
      return result
    })
    return finalEmergenciesQAs
  }

  return newValue
}

function mergeAccessNoteQAWithPreviousState(
  newValue: AccessNoteModel | null,
  stateValue: AccessNoteModel | null
) {
  return newValue && stateValue
    ? { ...newValue, answer: stateValue.answer }
    : newValue
}

function mergeVulnerabilityQAsWithPreviousState(
  newValue: VulnerabilityQAModel | null,
  stateValue: VulnerabilityQAModel | null
) {
  if (stateValue && newValue) {
    return {
      ...newValue,
      isHealthAffected: stateValue.isHealthAffected,
      level: stateValue.level,
      answerDetail: stateValue.answerDetail,
    }
  }

  return newValue
}

function mergeHealthAndSafetyQAsWithPreviousState(
  newValue: HealthAndSafetyQAModel[],
  stateValue: HealthAndSafetyQAModel[]
) {
  if (stateValue.length > 0) {
    const finalHealthAndSafetyQAs = newValue.map(
      (question: HealthAndSafetyQAModel) => {
        const result = question
        const index = stateValue.findIndex(
          (e) =>
            e.forEmergencyTypeId === question.forEmergencyTypeId &&
            e.forEmergencyDetailId === question.forEmergencyDetailId
        )
        if (index > -1) {
          result.isHealthAffected = stateValue[index].isHealthAffected
          result.answerDetail = stateValue[index].answerDetail
          result.level = stateValue[index].level
        }
        return result
      }
    )
    return finalHealthAndSafetyQAs
  }
  return newValue
}
const actions: ActionTree<IInsurerPortalState, any> = {
  async getLoqateApiKey({ dispatch }) {
    const res = await PolicyHolderController.GetLoqateApiKey()
    dispatch('generalModule/updateLoqateApiKey', res, { root: true })
  },
  async retrieveAddressLoqateApi(
    { dispatch },
    payload: { id: string; apiKey: string }
  ) {
    const { id, apiKey } = payload
    const result = await PolicyHolderController.RetrieveAddressLoqateApi(
      id,
      apiKey
    )

    const hasData = result && result.data.Items && result.data.Items.length > 0

    if (hasData) {
      const address = result.data.Items[0]
      if (!address.Error) {
        const addressLine1 =
          (address.SubBuilding + ' ' + address.BuildingName).trim() +
          ((address.SubBuilding || address.BuildingName) &&
          (address.BuildingNumber || address.Street)
            ? ', '
            : '') +
          (address.BuildingNumber + ' ' + address.Street).trim()
        const addressLine2 = address.District
        const addressLine3 = address.City
        const addressLine4 = address.AdminAreaName
        const postcode = address.PostalCode

        await Promise.all([
          dispatch('setAddressLine1', addressLine1),
          dispatch('setAddressLine2', addressLine2),
          dispatch('setAddressLine3', addressLine3),
          dispatch('setAddressLine4', addressLine4),
          dispatch('setPostcode', postcode),
        ])
      }
    }
    return hasData
  },
  async findAddressFromLoqateApi(
    _,
    payload: {
      text: string
      apiKey: string
      id: string
    }
  ) {
    const { text, apiKey, id } = payload
    const result = await PolicyHolderController.FindAddressFromLoqateApi(
      text,
      apiKey,
      id
    )

    const hasData = result && result.data.Items && result.data.Items.length > 0

    let searchResult = []

    if (hasData) {
      searchResult = result.data.Items.map((item: any) => ({
        ...item,
        Description:
          item.Type === 'Address'
            ? item.Text + ', ' + item.Description
            : item.Description + ', ' + item.Text,
      }))
    }

    return { hasData, searchResult }
  },
  async getPolicyQuestions(
    { dispatch, state },
    searchEmergencyQuestion: SearchEmergencyQuestionModel
  ) {
    const result = await PolicyHolderController.GetPolicyQuestions(
      searchEmergencyQuestion
    )

    const hasData = result !== null

    if (hasData) {
      const {
        questions,
        healthAndSafetyQAs,
        accessNoteQA,
        vulnerabilityQA,
        policyId,
        policyName,
        policyNumber,
        isPolicyCovered,
      } = result
      dispatch(
        'setEmergenciesQAs',
        mergeEmergencyQAsWithPreviousState(
          questions,
          state.insurerPortalModel.emergenciesQAs
        )
      )
      dispatch(
        'setHealthAndSafetyQAs',
        mergeHealthAndSafetyQAsWithPreviousState(
          healthAndSafetyQAs,
          state.insurerPortalModel.healthAndSafetyQAs
        )
      )

      dispatch(
        'setAccessNoteQA',
        mergeAccessNoteQAWithPreviousState(
          accessNoteQA,
          state.insurerPortalModel.accessNoteQA
        )
      )

      dispatch(
        'setVulnerabilityQA',
        mergeVulnerabilityQAsWithPreviousState(
          vulnerabilityQA,
          state.insurerPortalModel.vulnerabilityQA
        )
      )

      dispatch('setPolicyScheduleId', policyId)
      dispatch('setPolicyName', policyName)
      dispatch('setPolicyNumber', policyNumber)
      dispatch('setIsPolicyCovered', isPolicyCovered as boolean)
    }
    return hasData
  },
  async getQuestionModeJobDetails({ dispatch }) {
    const result = await PolicyHolderController.GetQuestionModeJobDetails()

    if (!result) return

    const {
      acceptedEmergencyIds,
      loggedEmergencies,
      returnEmergencyQuestionData,
      customerAvailability,
    } = result

    const isAllLoggedEmergenciesAccepted =
      acceptedEmergencyIds.length === loggedEmergencies.length

    dispatch('setAcceptedEmergencies', acceptedEmergencyIds)

    dispatch('setCustomerAvailability', customerAvailability)

    dispatch('setEmergenciesQAs', returnEmergencyQuestionData.questions)
    dispatch(
      'setHealthAndSafetyQAs',
      returnEmergencyQuestionData.healthAndSafetyQAs
    )
    dispatch('setAccessNoteQA', returnEmergencyQuestionData.accessNoteQA)
    dispatch('setVulnerabilityQA', returnEmergencyQuestionData.vulnerabilityQA)
    dispatch('setPolicyScheduleId', returnEmergencyQuestionData.policyId)
    dispatch('setPolicyName', returnEmergencyQuestionData.policyName)
    dispatch('setPolicyNumber', returnEmergencyQuestionData.policyNumber)
    dispatch(
      'setIsPolicyCovered',
      returnEmergencyQuestionData.isPolicyCovered as boolean
    )
    dispatch('setEmergencies', loggedEmergencies)

    return {
      isAllLoggedEmergenciesAccepted,
    }
  },
  async getEmergencies({ dispatch, rootGetters }) {
    try {
      const result = await PolicyHolderController.GetEmergencies()

      const clientTemplate: ClientTemplate =
        rootGetters['clientModule/clientTemplate']
      const emergencyDisplayList = clientTemplate.topEmergencies
        .filter((x) => x)
        .reduce((acc, cur) => {
          const emergency = result.find((e) => e.id === cur)
          if (emergency) {
            return [...acc, emergency]
          } else return acc
        }, [] as EmergencyModel[])

      dispatch('setEmergencyTypes', result)
      dispatch('setEmergencyDisplayList', emergencyDisplayList)
    } catch (error) {
      //  TODO: handle error
      dispatch('setEmergencyTypes', [])
      dispatch('setEmergencyDisplayList', [])
    }
  },
}
export default actions
