var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":"Upload Image - File Selection"}},[_c('div',[_c('label',{attrs:{"for":"imageUpload"}},[_c('span',{staticClass:"image-upload__btn",class:{ 'image-upload__btn--disabled': _vm.disabledUpload }},[_vm._v(" "+_vm._s(_vm.fileSelectionLabel)+" "),_c('span',{staticClass:"image-upload__icon"},[_c('v-icon',[_vm._v("attachment")])],1)])]),_c('h2',{staticClass:"font-weight-bold text-h6 float-right image-upload__counter"},[_vm._v(" "+_vm._s(_vm.imageCounter)+" ")]),_c('ValidationProvider',{ref:"provider",attrs:{"rules":{
        image: true,
        validFilesLength: {
          length: _vm.allowMultipleUploads ? 10 : 1,
          addedFilesLength: _vm.imagesToUpload.length,
        },
        imageUploadMimes: ['image/jpeg', 'image/jpg', 'image/png'],
        imageUploadSize: 1024,
      },"name":"Images"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('input',{staticClass:"image-upload__input",attrs:{"id":"imageUpload","type":"file","accept":"image/*","name":"image","multiple":_vm.allowMultipleUploads,"disabled":_vm.disabledUpload,"label":_vm.fileSelectionLabel,"aria-label":"Select Files"},on:{"change":function($event){return _vm.addPreviewImages($event)}}}),(_vm.imagesToUpload.length > 0)?_c('v-btn',{staticClass:"mb-3",attrs:{"aria-label":"Upload Files"},on:{"click":function($event){return _vm.uploadFiles()}}},[_vm._v(_vm._s(_vm.uploadButtonText))]):_vm._e(),_vm._l((errors),function(msg,index){return _c('div',{key:(msg + "-" + index)},[_c('h3',{staticClass:"red--text",attrs:{"aria-label":"Error message for image upload"}},[_vm._v(" "+_vm._s(msg)+" ")])])})]}}])}),_c('ImageDisplay',{attrs:{"images":_vm.imagesToUpload,"image-header":"Preview images to upload","region-label":"Preview images to upload","remove-image-label":"Remove preview Image"},on:{"removeImage":function($event){return _vm.removePreviewImage($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }