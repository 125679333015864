


































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { ContactPreference } from '@/common/enums'
import PhoneNumber from '@/components/PhoneNumber.vue'
import ClientTemplate from '@/models/client/client-template'
import VSelectCustom from './custom-vuetify/VSelectCustom'

@Component({
  components: { PhoneNumber, VSelectCustom },
})
export default class ClientDetail extends Vue {
  private clientTitleList: string[] = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']
  private contactPreferenceEnum = ContactPreference

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['generalModule/isUserVerifiedSuccess']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }
  private get contactPreference(): ContactPreference | null {
    return this.insurerPortal.contactPreference
  }
  private set contactPreference(value: ContactPreference | null) {
    this.$store.dispatch('insurerPortalModule/submitContactPreference', value)
  }

  private getSelectedItem(title: string) {
    if (this.insurerPortal && this.insurerPortal.clientTitle) {
      return this.insurerPortal.clientTitle === title
    }
    return false
  }

  private showPrivacyPolicyDetailsDialog() {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  public get isTermsAndServicesAccepted(): boolean {
    return this.$store.getters['cookieModule/termsAndServicesAccepted']
  }

  public set isTermsAndServicesAccepted(isTermsAndServicesAccepted: boolean) {
    this.$store.dispatch(
      'cookieModule/acceptTermsAndServices',
      isTermsAndServicesAccepted
    )
  }
}
