




































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import InsurerPortalModel from '@/models/InsurerPortalModel'

@Component
export default class PhoneNumber extends Vue {
  @Prop() public isValidationRequired: boolean
  @Prop() public selectClassName: string
  @Prop() public isDisabled: boolean

  // list of available countries (when adding to this list, make sure to add them to the style section)
  private countries: any[] = [
    { name: 'Great Britain', cc: '+44', code: 'gb' },
    { name: 'Ireland', cc: '+353', code: 'ie' },
    { name: 'International', code: 'eu' },
  ]
  private selectedCountry: any = this.countries[0]
  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get phoneNumber(): string {
    return this.formatPhoneNumber(
      this.insurerPortal.mobilePhone,
      PhoneNumberFormat.NATIONAL
    )
  }

  private set phoneNumber(value) {
    this.$store.dispatch(
      'insurerPortalModule/setPhoneNumber',
      this.formatPhoneNumber(value.trim())
    )
    if (!this.isCountryCodeCorrect(this.insurerPortal.mobilePhone)) {
      this.setDefaultCountry()
    }
  }

  private mounted() {
    if (this.phoneNumber) {
      this.phoneNumber = this.phoneNumber.trim().replace(/[^0-9]/g, '')
      this.setDefaultCountry()
    }
  }

  private changedCountryValue(value: any): void {
    this.selectedCountry = value
  }
  private isCountryCodeCorrect(phoneNumber: string): boolean {
    if (!this.selectedCountry.cc) {
      return false
    }
    const isCCInputted =
      phoneNumber.length - this.selectedCountry.cc.length >= 1
    return !isCCInputted || phoneNumber.includes(this.selectedCountry.cc)
  }

  private formatPhoneNumber(
    value: string,
    formatType = PhoneNumberFormat.INTERNATIONAL
  ) {
    if (this.selectedCountry.code === 'eu') {
      return value
    }
    let util: any = PhoneNumberUtil.getInstance()
    try {
      const phoneNumberObject: any = util.parseAndKeepRawInput(
        value,
        this.selectedCountry.code
      )
      return util.format(phoneNumberObject, formatType).replace(/[ (-)]/g, '')
    } catch (error) {
      // ignore this error
    }
    return value
  }

  private setDefaultCountry(): void {
    // check if we can get a country code out of it
    if (
      !this.insurerPortal.mobilePhone ||
      this.insurerPortal.mobilePhone.startsWith('0')
    ) {
      this.selectedCountry = this.countries[0]
    } else if (this.insurerPortal.mobilePhone.startsWith('+')) {
      const matchCountryCodes: string = this.countries
        .filter((x) => x.cc)
        .map((x) => x.cc.replace('+', '\\+'))
        .join('|')
      const matchPhoneNumber = matchCountryCodes + '\\d+'
      if (RegExp(matchPhoneNumber).test(this.insurerPortal.mobilePhone)) {
        const matches = this.insurerPortal.mobilePhone.match(matchCountryCodes)
        if (matches && matches.length > 0) {
          const index = this.countries.map((e) => e.cc).indexOf(matches[0])
          if (index >= 0) {
            this.selectedCountry = this.countries[index]
          } else {
            this.selectedCountry = this.countries[0]
          }
        } else {
          this.selectedCountry = this.countries[0]
        }
      } else {
        this.selectedCountry = this.countries[this.countries.length - 1]
      }
    }
  }

  private getSelectedItem(code: string) {
    return this.selectedCountry && this.selectedCountry.code === code
  }

  public get flagUrl() {
    return `url('${process.env.PUBLIC_URL}/img/flags.png')`
  }
}
