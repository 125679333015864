class SnackBar {
  public message = ''
  public showSnackbar = false

  constructor(message: string) {
    this.message = message
    this.showSnackbar = false
  }
}

export default SnackBar
