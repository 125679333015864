// https://vee-validate.logaretm.com/v3/guide/rules.html#rules Additional rules can be found here.

import { extend } from 'vee-validate'
import { dropdownRequired } from './dropdown-required'

import {
  required,
  email,
  image,
  size,
  double,
  mimes,
  length,
  numeric,
} from 'vee-validate/dist/rules'
import {
  validPhoneNumber,
  validCountry,
  possiblePhoneNumber,
} from './telephone-validate'
import { validFilesLength } from './image-upload-rules'
import { recaptchaResponse, recaptchaToken } from './rules/recaptcha-rules'
import {
  validDateFormat,
  validDateInTimeSlotPicker,
  emergencyValidDateTime,
} from './datetime-rule'

export const addRules = (): void => {
  extend('required', required)
  extend('email', email)
  extend('image', image)
  extend('size', size)
  extend('double', double) // This replaces decimal from v2
  extend('mimes', mimes)
  extend('validPhoneNumber', validPhoneNumber)
  extend('validCountry', validCountry)
  extend('possiblePhoneNumber', possiblePhoneNumber)
  extend('validFilesLength', validFilesLength)
  extend('length', length)
  extend('numeric', numeric)
  extend('dropdownRequired', dropdownRequired)
  extend('validDateFormat', validDateFormat)
  extend('emergencyValidDateTime', emergencyValidDateTime)
  extend('imageUploadMimes', {
    ...mimes,
    message: 'Image type must be JPG, JPEG or PNG',
  }) // create new rule name to override the message for images upload
  extend('imageUploadSize', {
    ...size,
    message: 'Cannot upload images larger than 1024kb',
  }) // create new rule name to override the message for images upload
  extend('recaptchaToken', recaptchaToken)
  extend('recaptchaResponse', recaptchaResponse)
  extend('validDateInTimeSlotPicker', validDateInTimeSlotPicker)
}
