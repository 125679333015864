import ClientTemplate from '@/models/client/client-template'
import { initialize } from 'launchdarkly-js-client-sdk'
import type { LDClient, LDContext, LDOptions } from 'launchdarkly-js-client-sdk'
import type { PluginObject, VueConstructor } from 'vue'
import { Store } from 'vuex'

const defaultContext: LDContext = {
  kind: 'customer-portal',
  key: 'www',
  name: 'Home Emergency',
}

interface LdUserPluginOptions {
  envKey: string
  ldOptions: LDOptions | undefined
  store: Store<any>
}

let ldClient: LDClient | undefined
export function useLdClient(): LDClient | undefined {
  return ldClient
}

const ldUserPlugin: PluginObject<LdUserPluginOptions> = {
  install: (vue: VueConstructor, options: LdUserPluginOptions | undefined) => {
    if (!options) {
      throw new Error('Missing options value. ')
    }

    ldClient = initialize(options.envKey, defaultContext, {
      streaming: true,
    })
    vue.prototype.$ld = ldClient

    options.store.watch(
      (_state, getters) => getters['clientModule/clientTemplate'],
      (newVal: ClientTemplate) => {
        if (newVal.subDomain && ldClient) {
          const newContext: LDContext = {
            kind: 'customer-portal',
            key: newVal.subDomain,
            name: newVal.displayName,
          }
          vue.prototype.$ld.identify(newContext)
        }
      }
    )
  },
}

export default ldUserPlugin
