import JobController from '@/api/jobController'
import { ImageType } from '@/common/enums'
import AddCATInvoiceImageModel from '@/models/claim/AddCATInvoiceImageModel'
import ImageBase from '@/models/image/image-base'
import IImageBase from '@/models/image/interfaces/image-base'
import PictureUploadModel from '@/models/PictureUploadModel'
import router from '@/router'

class ImageService {
  private static serviceInstance: ImageService

  public static get instance() {
    return this.serviceInstance || (this.serviceInstance = new this())
  }

  public uploadImage(image: ImageBase): Promise<string | null> {
    const uploadFunction = this.selectUploadFunction(image.imageType)
    return uploadFunction
      ? uploadFunction(image)
      : new Promise((resolve) => resolve(null))
  }

  public async deleteImage(image: ImageBase): Promise<boolean> {
    return await JobController.DeleteImage(image)
  }

  public async retrieveUploadedImages(
    imageType: ImageType
  ): Promise<IImageBase[]> {
    return await JobController.GetUploadedImages(imageType)
  }

  public async authoriseCustomerToUploadReceipt(
    recordId: string
  ): Promise<boolean> {
    return await JobController.authoriseCustomerToUploadReceipt(recordId)
  }

  private async uploadExampleofEmergency(
    image: ImageBase
  ): Promise<string | null> {
    const cosmosImageDoc: PictureUploadModel = new PictureUploadModel()
    cosmosImageDoc.uploadedBy = 'Customer'
    return await JobController.uploadExampleofEmergency(image, cosmosImageDoc)
  }

  private async uploadCatInvoice(image: ImageBase): Promise<string | null> {
    const catInvoiceImage = new AddCATInvoiceImageModel()
    catInvoiceImage.id = router.currentRoute.params.recordId
    return await JobController.uploadCatInvoiceImage(image, catInvoiceImage)
  }

  private selectUploadFunction(
    imageType: ImageType
  ): ((image: ImageBase) => Promise<string | null>) | null {
    let selectedFunction = null

    for (const [imageTypeKey, functionValue] of this.availableUploadFunctions) {
      if (imageTypeKey === imageType) {
        selectedFunction = functionValue
        break
      }
    }
    return selectedFunction
  }

  private get availableUploadFunctions(): Map<
    ImageType,
    (image: ImageBase) => Promise<string | null>
  > {
    const functions = new Map<
      ImageType,
      (image: ImageBase) => Promise<string | null>
    >()
    functions.set(ImageType.ExampleofEmergency, this.uploadExampleofEmergency)
    functions.set(ImageType.CATInvoice, this.uploadCatInvoice)
    return functions
  }
}

// export an instance in the global namespace
export const imageService = ImageService.instance
