









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MessageCard extends Vue {
  @Prop({ default: 'Something Went Wrong!' }) private message: string
}
