export const englishLocale = {
  messages: {
    required: 'This field is required',
    dropdownRequired: 'An answer to this question is mandatory.',
  },
  // Custom Fields
  fields: {
    imageUpload: {
      size: 'Image size must not exceed 1024 KB',
      mimes: 'Uploaded image must be of type JPG, JPEG, or PNG',
      ext: 'Uploaded image must be of type JPG, JPEG, or PNG',
    },
    emergencyQuestion: {
      required: 'An answer to this question is mandatory.',
    },
    emergencyQuestionNumber: {
      required: 'An answer to this question is mandatory.',
      double: 'An answer must be numeric and may contain decimal points.',
    },
  },
}
