var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":'Time slot picker for' + _vm.slotType}},[_c('v-row',{staticClass:"time-slot"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"mr-1 md-icon",style:({ color: _vm.clientTemplate.complementaryColor })},[_vm._v("date_range")]),_c('b',{staticClass:"time-slot__header-text",attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.dateSelection ? 'Select Date' : _vm.headerText)+" ")])],1),_c('v-col',{staticClass:"flex pb-0 text-right",attrs:{"cols":"6"}},[(_vm.canDelete)?_c('v-icon',{staticClass:"mr-1 md-icon",attrs:{"large":""},on:{"click":_vm.onDelete}},[_vm._v("close")]):_vm._e()],1),(_vm.showControlOption)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"m-1 pa-0 time-slot__btn",attrs:{"text":"","color":"primary","small":"","aria-label":_vm.selectDateButtonTitle},on:{"click":_vm.onClickPresentDate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onClickPresentDate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.selectDateButtonTitle)+" ")]),_c('v-btn',{staticClass:"m-1 pa-0 time-slot__btn",attrs:{"text":"","color":"primary","small":"","aria-label":"Enter date manually"},on:{"click":_vm.onClickManuallyDate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.setFocusToDateInput.apply(null, arguments)}}},[_vm._v(" Enter date manually ")])],1):_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"11"}},[_c('ValidationProvider',{attrs:{"name":"DatePicker","rules":{
              required: true,
              validDateFormat: {
                format: _vm.globalDateFormat,
              },
              validDateInTimeSlotPicker: {
                format: _vm.globalDateFormat,
                isDateNotSelected: _vm.isDateNotSelected(_vm.chosenFormattedDate),
                isSelectedDateIsSameOrAfterMinDate:
                  _vm.isSelectedDateIsSameOrAfterMinDate(_vm.chosenFormattedDate),
              },
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{ref:"dateInput",staticClass:"required",attrs:{"return-masked-value":"","mask":_vm.getDateFormatMaskValue,"placeholder":_vm.globalDateFormat,"error-messages":errors,"aria-label":("" + (_vm.chosenFormattedDate
                  ? 'Selected date ' + _vm.chosenFormattedDate
                  : 'Please enter date in ' + _vm.globalDateFormat + 'format'))},on:{"keyup":function($event){return _vm.validateDate(_vm.chosenFormattedDate)}},model:{value:(_vm.chosenFormattedDate),callback:function ($$v) {_vm.chosenFormattedDate=$$v},expression:"chosenFormattedDate"}})]}}])})],1),_c('v-col',{staticClass:"pt-3 pa-0",attrs:{"cols":"1"}},[(_vm.dateSelection)?_c('v-menu',{ref:"datePickerMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"slot":"activator","icon":"","aria-label":"Open calender dialog"},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-0 text--darken-2",attrs:{"color":"grey"}},[_vm._v("date_range")])],1)]}}],null,false,412232014),model:{value:(_vm.showDatePickerSelection),callback:function ($$v) {_vm.showDatePickerSelection=$$v},expression:"showDatePickerSelection"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.setMinDate,"allowed-dates":_vm.isDateAllowToSelect},model:{value:(_vm.chosenDate),callback:function ($$v) {_vm.chosenDate=$$v},expression:"chosenDate"}},[_c('v-spacer'),_c('v-btn',{staticClass:"time-slot__btn pa-0 ma-0",attrs:{"text":"","color":"primary","label":"Cancel","aria-label":"Cancel"},on:{"click":function($event){_vm.showDatePickerSelection = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"time-slot__btn pa-0 ma-0",attrs:{"text":"","color":"primary","label":"Ok","aria-label":"Ok"},on:{"click":function($event){return _vm.onSelectDatePicker(_vm.chosenDate)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"pa-0",class:_vm.headerText ? 'xs12' : 'xs12'},[_c('ValidationProvider',{attrs:{"name":"Select Time Slot(s)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete-custom',{ref:"multipleSelectionTimeSlots",class:'time-slot-picker' + _vm.slotType,attrs:{"id":'time-slot-picker' + _vm.slotType,"items":_vm.todayTimeSlot,"aria-label":"Select Time Slot(s)","placeholder":"Select Time Slot(s)","item-text":"slot","item-value":"slotId","multiple":"","chips":"","return-object":"","persistent-hint":"","aria-owns":'time-slot-picker' + _vm.slotType,"aria-haspopup":"listbox","error-messages":errors},on:{"change":function($event){return _vm.onSlotChange(_vm.selectedSlots, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return (
                  data.item.slot !== 'Daytime' && data.item.slot !== '24hrs'
                )?[_c('v-chip',{key:JSON.stringify(data.item),staticClass:"chip--select-multi",style:({
                    borderColor: _vm.clientTemplate.inputTextColor,
                    color: _vm.clientTemplate.inputTextColor,
                  }),attrs:{"input-value":data.selected,"close":"","small":""},on:{"click:close":function($event){return _vm.removeSelectedSlot(data.item, data)}}},[_vm._v(" "+_vm._s(data.item.slot)+" ")])]:undefined}},{key:"item",fn:function(data){return [_c('div',{staticClass:"custom-slot"},[_c('div',{class:{
                      'custom-slot__checkbox': true,
                      'custom-slot__checkbox--selected': _vm.isSlotSelected(
                        data.item.slot
                      ),
                    }}),_c('div',{staticClass:"custom-slot__text"},[_c('span',[_vm._v(_vm._s(data.item.slot === '24hrs' ? 'Rest of Day' : data.item.slot))])])])]}}],null,true),model:{value:(_vm.selectedSlots),callback:function ($$v) {_vm.selectedSlots=$$v},expression:"selectedSlots"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }