import Vue from 'vue'
import Vuex from 'vuex'
import { cookieModule } from '@/vuex/modules/cookie-module'
import { clientModule } from '@/vuex/modules/client-module'
import { generalModule } from '@/vuex/modules/general-module'
import { imageModule } from '@/vuex/modules/image-module'
import { insurerPortalModule } from '@/vuex/modules/insurer-portal-module'
import { claimTrackingModule } from '@/vuex/modules/claim-tracking-module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cookieModule,
    clientModule,
    generalModule,
    imageModule,
    insurerPortalModule,
    claimTrackingModule,
  },
})
