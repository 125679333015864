









































import ClientTemplate from '@/models/client/client-template'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ThanksDialog extends Vue {
  @Prop({ default: false }) private isOpen: boolean
  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }
  private get clientName(): string {
    return this.$route.params.clientName
  }

  private trackClaim() {
    const url: string = this.clientName
      ? `${this.clientName}/claimtracking`
      : 'claimtracking'
    this.$router.replace(`/${url}/${this.getTrackingId}`)
  }

  private get isClaimCovered(): boolean {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
      .isPolicyCovered
  }
}
