
























































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import Shared from '@/common/shared'
import { ImageType } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import EmergencyQA from '@/models/EmergencyQA'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import EmergencySelection from '@/components/EmergencySelection.vue'
import ClientDetail from '@/components/ClientDetail.vue'
import CustomerAvailabilityPreview from '@/components/CustomerAvailabilityPreview.vue'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EditableHeader from '@/components/EditableHeader.vue'
import CustomerAddress from '@/components/CustomerAddress.vue'
import CustomerVerification from '@/components/CustomerVerification.vue'

import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ThanksDialog from '@/components/ThanksDialog.vue'

@Component({
  name: 'InsurerPortal',
  components: {
    EmergencyQuestionGenerator,
    EmergencySelection,
    ClientDetail,
    DateTimePicker,
    CustomerAvailabilityPreview,
    EditableHeader,
    CustomerAddress,
    CustomerVerification,
    MessageCard,
    ImageUpload,
    ValidationObserver,
    ValidationProvider,
    ThanksDialog,
  },
  provide() {
    return {
      formWrapperGetter: () => this.$refs.formWrapper,
    }
  },
})
export default class InsurerPortal extends Vue {
  private ready = false

  private localTimer: Date = new Date()
  private localTimerForClaim: Date = new Date()

  private defaultQuestionSelectionOption = 'Select an Option'
  private emergencyImageType: ImageType = ImageType.ExampleofEmergency
  private isThanksDialogOpen = false

  $refs!: {
    formWrapper: InstanceType<typeof ValidationObserver>
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get totalSteps(): number {
    return this.$store.getters['generalModule/navigation'].totalSteps
  }

  private get highestVisitedStep(): number {
    return this.$store.getters['generalModule/navigation'].highestVisitedStep
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  public get emergenciesQAs() {
    return this.insurerPortal.emergenciesQAs
  }

  private set emergenciesQAs(payload: EmergencyQA[]) {
    this.$store.dispatch('insurerPortalModule/setEmergenciesQAs', payload)
  }

  private get healthAndSafetyQAs() {
    return this.insurerPortal.healthAndSafetyQAs
  }

  private get accessNoteQA() {
    return this.insurerPortal.accessNoteQA
  }

  private get vulnerabilityQA() {
    return this.insurerPortal.vulnerabilityQA
  }

  private get emergencies() {
    return this.insurerPortal.emergencies
  }

  private set emergencies(payload: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', payload)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.insurerPortal.customerAvailability
  }

  private set customerAvailability(payload: CustomerAvailabilityModel) {
    this.$store.dispatch('insurerPortalModule/setCustomerAvailability', payload)
  }

  public stepTitles: string[] = [
    'Select',
    'Detail',
    'Verification',
    'Address',
    'Explain',
    'Customer Availability',
    'Upload',
  ]

  public currentTab = 'step1'

  public gaNextStepEvents = [
    { step: 1, event: 'Emergencies Details Completed' },
    { step: 2, event: 'Contact Preference Completed' },
    { step: 3, event: 'User Verification Completed' },
    { step: 4, event: 'Address Search Completed' },
    { step: 5, event: 'Emergency Question Completed' },
    { step: 6, event: 'Customer Availability' },
    { step: 7, event: 'Image Upload Completed' },
  ]

  public gaPreviousStepEvents = [
    { step: 2, event: 'Back to Emergency Details' },
    { step: 3, event: 'Back To Contact Preference' },
    { step: 4, event: 'Back To User Verification' },
    { step: 5, event: 'Back To Address Search' },
    { step: 6, event: 'Back To Emergency Question List' },
  ]

  public get currentTabHref(): string {
    return `step${this.currentStep}`
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  // We take a param, incase we need to move forward
  // more than one step in the form, in the future
  public async nextStep(stepValue = 1) {
    if (!(await this.validateCurrentStep())) return
    const gaEvent = this.gaNextStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'NEXT')
    }

    this.$store.dispatch('generalModule/nextStep', stepValue)
    this.scrollToTop()
  }

  public previousStep(stepValue = 1) {
    const gaEvent = this.gaPreviousStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'PREVIOUS')
    }
    this.$store.dispatch('generalModule/previousStep', stepValue)
    this.scrollToTop()
  }

  public async validateCurrentStep(): Promise<boolean> {
    const isValid = await this.activeStepValidator.validate()
    if (!isValid) {
      for (const key of Object.keys(this.activeStepValidator.fields).sort()) {
        if (this.activeStepValidator.fields[key].invalid) {
          this.activeStepValidator.refs[key]?.$el.scrollIntoView()
          return false
        }
      }
    }
    return true
  }

  public get activeStepValidator() {
    return this.$refs.formWrapper.observers[this.currentStep - 1]
  }

  public async submitForm() {
    await this.$refs.formWrapper.validate()
  }

  private async created() {
    await this.$store.dispatch('insurerPortalModule/getEmergencies')
    this.ready = true

    this.handleLinkClicksInGA('Client Portal', 'LOADED')
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private async saveClaim() {
    if (!(await this.validateCurrentStep())) return
    if (this.appInEditMode) {
      this.nextStep()
      return
    }
    this.isLoading = true
    const emergenciesWithoutDetail = this.insurerPortal.emergencies.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ emergencyDetail, ...x }) => x
    )
    this.emergencies = emergenciesWithoutDetail

    // find answer with default option value and set null
    this.emergenciesQAs = this.emergenciesQAs.map((x) =>
      x.answer === this.defaultQuestionSelectionOption
        ? { ...x, answer: '' }
        : x
    )

    try {
      const result = await this.$store.dispatch('insurerPortalModule/addJob', {
        subDomain: window.location.host,
        portalId: this.$store.getters['clientModule/portalId'],
        model: this.insurerPortal,
      })
      if (result) {
        this.nextStep()
      }
      this.isLoading = false
    } catch (error) {
      // TODO: handle error
      this.isLoading = false
    }
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }

  private handleLinkClicksInGA(
    categoryName: string,
    actionName: string,
    time: Date | null = null
  ) {
    if (this.appInEditMode) {
      // return if app is in edit mode
      return
    }
    // send event updates to google analytics
    Shared.sendEventsInGoogleAnalytics(
      categoryName,
      actionName,
      time ? time : this.localTimer
    )
    // reset timer for second button click
    this.localTimer = new Date()
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get clientName(): string {
    return this.$route.params.clientName
  }
}
