export default class CookieBannerSettings {
  public cookiePolicyLink = 'https://www.cet-uk.com/customer-privacy-notice'

  public privacyPolicyText: string =
    'By clicking Accept all cookies, you agree CET UK can store cookies on your device and' +
    'disclose information in accordance with our'

  public modalText: string =
    'When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies.' +
    'This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to.' +
    'The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your' +
    'right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences.' +
    'Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.'

  public necessaryLabel = 'Strictly Necessary'

  public necessaryTooltipText: string =
    'Necessary cookies help make a website usable by enabling basic functions' +
    'like page navigation and access to secureareas of the website. The website cannot function properly without these cookies.'
}
