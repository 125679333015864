import SessionController from '@/api/sessionController'
import SignalRController from '@/api/signalrController'
import Environment from '@/common/environment'
import EnvironmentSecrets from '@/models/generic/environment-secrets'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import SnackBar from '@/models/generic/snackbar'
import Navigation from '@/models/navigation/navigation'
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import VerifyPortalAccessUserModel from '@/models/VerifyPortalAccessUserModel'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import GeneralState from '../states/general-state'
import { IGeneralState } from '../states/interfaces/general-state'

export const generalState: IGeneralState = new GeneralState()

export const getters: GetterTree<IGeneralState, any> = {
  environment: (state): Environment => state.environment,
  errorSnackBar: (state): ErrorSnackBar => state.errorSnackBar,
  snackBar: (state): SnackBar => state.snackBar,
  navigation: (state): Navigation => state.navigation,
  recaptchaToken: (state): string | null => state.recaptchaToken,
  recaptchaResponse: (state): RecaptchaResponse | null =>
    state.recaptchaResponse,
  loqateApiKey: (state): string => state.loqateApiKey || '',
  trackingId: (state): string => state.trackingId || '',
  isUserVerifiedSuccess: (state): boolean => state.isUserVerifiedSuccess,
}

export const mutations: MutationTree<IGeneralState> = {
  setSnackBar: (state: IGeneralState, snackBar: SnackBar) => {
    snackBar.showSnackbar = true
    state.snackBar = snackBar
  },
  setErrorSnackbar: (state: IGeneralState, errorSnackbar: ErrorSnackBar) => {
    errorSnackbar.showSnackbar = true
    state.errorSnackBar = errorSnackbar
  },
  nextStep: (state: IGeneralState, stepValue: number) => {
    state.navigation.currentStep += stepValue
    if (state.navigation.currentStep > state.navigation.highestVisitedStep) {
      // We use this to keep data active in the DOM for previous steps.
      state.navigation.highestVisitedStep = state.navigation.currentStep
    }
  },
  previousStep: (state: IGeneralState, stepValue: number) => {
    state.navigation.currentStep -= stepValue
  },
  setRecaptchaToken: (state: IGeneralState, recaptchaToken: string | null) => {
    state.recaptchaToken = recaptchaToken
  },
  setRecaptchaResponse: (
    state: IGeneralState,
    recaptchaResponse: RecaptchaResponse
  ) => {
    state.recaptchaResponse = recaptchaResponse
  },
  setIsLoading: (state: IGeneralState, isLoading: boolean) => {
    state.navigation.isLoading = isLoading
  },
  updateLoqateApiKey: (state: IGeneralState, loqateApiKey: string) => {
    state.loqateApiKey = loqateApiKey
  },
  updateTrackingId: (state: IGeneralState, sd: string) => {
    state.trackingId = sd
  },
  setUserVerifiedStatus: (state: IGeneralState, status: boolean) => {
    state.isUserVerifiedSuccess = status
  },
  setEnvironmentSetting: <K extends keyof Environment>(
    state: IGeneralState,
    payload: { key: K; value: Environment[K] }
  ) => {
    state.environment[payload.key] = payload.value
  },
}

export const actions: ActionTree<IGeneralState, any> = {
  submitShowSnackBar({ commit }, snackbar: SnackBar) {
    commit('setSnackbar', snackbar)
  },
  submitShowErrorSnackbar({ commit }, errorSnackbar: ErrorSnackBar) {
    commit('setErrorSnackbar', errorSnackbar)
  },
  nextStep({ commit }, stepValue: 1) {
    commit('nextStep', stepValue)
  },
  previousStep({ commit }, stepValue: 1) {
    commit('previousStep', stepValue)
  },
  submitRecaptchaToken({ commit }, recaptchaToken: string | null) {
    commit('setRecaptchaToken', recaptchaToken)
  },
  setUserVerifiedStatus({ commit }, status: boolean) {
    commit('setUserVerifiedStatus', status)
  },
  async submitVerifyCustomer(
    { commit },
    verifyCustomerModel: VerifyPortalAccessUserModel
  ) {
    const verifyCustomerResponse = await SessionController.verifyCustomer(
      verifyCustomerModel
    )
    if (verifyCustomerResponse) {
      commit('setRecaptchaResponse', verifyCustomerResponse.recaptchaResponse)
      if (verifyCustomerResponse.trackingId) {
        commit('updateTrackingId', verifyCustomerResponse.trackingId)
      }
    }
  },
  async submitVerifyRecaptcha({ commit }, recaptchaToken: string) {
    commit(
      'setRecaptchaResponse',
      await SessionController.verifyRecaptcha(recaptchaToken)
    )
  },
  setIsLoading({ commit }, isLoading: boolean) {
    commit('setIsLoading', isLoading)
  },
  updateLoqateApiKey({ commit }, loqateApiKey: string) {
    commit('updateLoqateApiKey', loqateApiKey)
  },
  updateTrackingId({ commit }, id: string) {
    commit('updateTrackingId', id)
  },
  async createNewSignalRNegotiateRequest(_, hubName: string) {
    return await SignalRController.CreateNewSignalRNegotiateRequest(hubName)
  },
  async addUserToNotificationsForJobTracking() {
    return await SignalRController.AddUserToNotificationsForJobTracking()
  },
  async submitRetrieveEnvironmentSecrets({ commit, dispatch }) {
    const secrets = await SessionController.getEnvironmentSecrets()
    if (!secrets) {
      dispatch(
        'generalModule/submitShowErrorSnackbar',
        new ErrorSnackBar(`Failed to retrieve environment secrets`),
        {
          root: true,
        }
      )
    } else {
      const keys = Object.keys(secrets) as Array<keyof EnvironmentSecrets>
      keys.forEach((key) => {
        if (!secrets[key]) {
          dispatch(
            'generalModule/submitShowErrorSnackbar',
            new ErrorSnackBar(
              `App settings error - ${key} must not be null. Please contact support`
            ),
            {
              root: true,
            }
          )
          return
        }
        commit('setEnvironmentSetting', { key, value: secrets[key] })
      })
    }
  },
}

export const generalModule: Module<IGeneralState, any> = {
  state: generalState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
