import moment from 'moment'
import Confirmation from '@/components/Confirmation.vue'
import DOMPurify from 'dompurify'
import { VueGtag } from 'vue-gtag'

export default class Shared {
  // globally defined confirmation popup
  public static confirmationPopup: Confirmation
  private static $gtag: VueGtag

  public static setGtagInstance(gtag: VueGtag) {
    this.$gtag = gtag
  }

  // set focus to first element which has validation error
  public static setValidationFocus(el: HTMLElement): void {
    const errorItem: any = el.querySelector('.error--text')
    if (errorItem) {
      errorItem.focus()
      errorItem.getElementsByTagName('input')[0].focus()
    }
  }

  public static getFormatedDate(
    dateValue: moment.Moment,
    format: string
  ): string {
    if (dateValue) {
      if (moment.isMoment(dateValue) && dateValue.isValid()) {
        return dateValue.format(format)
      } else {
        return '--/--/----'
      }
    }
    return '--/--/----'
  }

  public static SortData<T, K extends keyof T>(data: T[], columnName: K): T[] {
    if (data != null) {
      data.sort((a: T, b: T) => {
        let nameA = String(a[columnName]).toLowerCase()
        let nameB = String(b[columnName]).toLowerCase()

        if (nameA === 'undefined') {
          nameA = ''
        }
        if (nameB === 'undefined') {
          nameB = ''
        }
        // sort string ascending
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return -1 // default return value (no sorting)
      })
    }

    return data
  }

  public static getMinutesFromSecond(
    duration: number,
    defaultValue: string,
    length: number
  ): string {
    return (new Array(length + 1).join(defaultValue) + duration).slice(-length)
  }

  public static sendEventsInGoogleAnalytics(
    categoryName: string,
    actionName: string,
    localTimer: Date
  ) {
    if (this.$gtag) {
      // get the time difference of two buttom click in milliseconds
      let actionTime = new Date().getTime() - localTimer.getTime()
      actionTime = actionTime < 0 ? 0 : actionTime
      // send notification to google analytics
      this.$gtag.event('page_view', {
        method: actionName,
        event_category: categoryName,
        page_title: categoryName,
        event_label: window.location.host,
        value: actionTime,
      })
    }
  }

  public static sanitizeHTML(html: string): string {
    return DOMPurify.sanitize(html)
  }
}
