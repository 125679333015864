import ClaimTrackingState from '../states/claim-tracking-state'
import { IClaimTrackingState } from '../states/interfaces/claim-tracking-state'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import JobController from '@/api/jobController'

export const claimTrackingState: IClaimTrackingState = new ClaimTrackingState()

export const getters: GetterTree<IClaimTrackingState, any> = {
  termsAndServicesAccepted: (state): boolean => state.termsAndServicesAccepted,
  jobId: (state): string => state.jobId,
  trackingId: (state): string => state.trackingId,
  isUserVerifiedSuccess: (state): boolean => state.isUserVerifiedSuccess,
  token: (state): string => state.token,
}

export const mutations: MutationTree<IClaimTrackingState> = {
  updateTrackingId: (state: IClaimTrackingState, trackingId: string) => {
    state.trackingId = trackingId
  },
  setUserVerifiedStatus: (state: IClaimTrackingState, status: boolean) => {
    state.isUserVerifiedSuccess = status
  },
  resetClaimTrackingState: (state: IClaimTrackingState, jobId: string) => {
    state.termsAndServicesAccepted = false
    state.jobId = jobId
    state.trackingId = ''
    state.isUserVerifiedSuccess = false
  },
  updateTermsAndServices: (state: IClaimTrackingState, accepted: boolean) => {
    state.termsAndServicesAccepted = accepted
  },
  setToken: (state: IClaimTrackingState, token: string) => {
    state.token = token
  },
}

export const actions: ActionTree<IClaimTrackingState, any> = {
  updateTrackingId({ commit }, trackingId: string) {
    commit('updateTrackingId', trackingId)
  },
  setUserVerifiedStatus({ commit }, status: boolean) {
    commit('setUserVerifiedStatus', status)
  },
  resetClaimTrackingState({ commit }, jobId: string) {
    commit('resetClaimTrackingState', jobId)
  },
  updateTermsAndServices({ commit }, accepted: boolean) {
    commit('updateTermsAndServices', accepted)
  },
  setToken({ commit }, token: string) {
    commit('setToken', token)
  },
  async getJobTrackingDocuments({ state }) {
    return await JobController.GetJobTrackingDocumentsWithToken(state.token)
  },
}

export const claimTrackingModule: Module<IClaimTrackingState, any> = {
  state: claimTrackingState,
  mutations,
  getters,
  actions,
  namespaced: true,
}
