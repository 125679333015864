



















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ClaimTrackingDetails from '@/components/ClaimTrackingDetails.vue'
import Environment from '@/common/environment'
import ClaimTrackingModel from '@/models/ClaimTracking/ClaimTrackingModel'
import ClaimEmergencyDetail from '@/models/ClaimTracking/ClaimEmergencyDetail'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import EngineerAssigned from '@/models/ClaimTracking/EngineerAssigned'
import CoverIncluded from '@/models/ClaimTracking/CoverIncluded'
import EngineerOnTheWay from '@/models/ClaimTracking/EngineerOnTheWay'
import EngineerOnSite from '@/models/ClaimTracking/EngineerOnSite'
import ClaimVisitComplete from '@/models/ClaimTracking/ClaimVisitComplete'
import ClaimRequest from '@/models/ClaimTracking/ClaimRequest'
import { ClaimTrackingType } from '@/common/enums'
import Shared from '@/common/shared'
import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import { ValidationObserver } from 'vee-validate'
import CustomerVerificationClaimTracking from '@/components/CustomerVerificationClaimTracking.vue'
import JobController from '@/api/jobController'

import Vuebar from 'vuebar'
Vue.use(Vuebar)

@Component({
  name: 'ClaimTracking',
  components: {
    ClaimTrackingDetails,
    MessageCard,
    ValidationObserver,
    CustomerVerificationClaimTracking,
  },
})
export default class ClaimTracking extends Vue {
  public claimTrackingModelList: ClaimTrackingModel[] = []
  private claimTrackingModelDetail: ClaimTrackingModel =
    new ClaimTrackingModel()
  private claimEmergencyDetail: ClaimEmergencyDetail[] = []
  public selectedClaimIndex = 0
  private signalRHub: SignalRHubConnection = new SignalRHubConnection(
    'jobTracking'
  )
  public engineerLocationPreviousLatitude = 0
  public engineerLocationPreviousLongitude = 0
  public headingMagneticNorth = 0
  public isJobCompleted = false
  private isCurrentTabIsActive = true
  private localTimer: Date = new Date()
  private ldClientReady = false
  public jobId = ''

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private async created() {
    await this.$ld.waitForInitialization()
    this.ldClientReady = true
    if (this.isTFAEnabled()) {
      JobController.GetJobId().then((res) => {
        this.jobId = res
      })
    } else {
      this.GetJobTrackingDocuments()
      this.signalRJobTrackingRequest()
    }

    this.$store.dispatch('generalModule/updateTrackingId', this.trackingId)
    document.addEventListener('visibilitychange', () => {
      this.isCurrentTabIsActive = document.hidden ? false : true
    })
  }

  public get ready(): boolean {
    return this.ldClientReady && (!this.isTFAEnabled() || !!this.jobId)
  }

  private async GetJobTrackingDocuments() {
    this.isJobCompleted = false
    try {
      this.claimTrackingModelList = await this.$store.dispatch(
        'insurerPortalModule/getJobTrackingDocuments'
      )
    } catch (err: any) {
      this.claimTrackingModelList = []

      if (err.response.status === 401) {
        this.isJobCompleted = true
      } else {
        this.$router.replace('/')
      }
      return
    }

    this.claimTrackingModelList.forEach((element) => {
      this.claimEmergencyDetail.push(element.claimEmergencyDetail)
    })
  }

  private async GetJobTrackingDocumentsWithToken() {
    this.isJobCompleted = false
    try {
      this.claimTrackingModelList = await this.$store.dispatch(
        'claimTrackingModule/getJobTrackingDocuments'
      )
    } catch (err: any) {
      this.claimTrackingModelList = []

      if (err.response.status === 401) {
        this.isJobCompleted = true
      } else if (err.response.status === 403) {
        this.$store.dispatch(
          'claimTrackingModule/resetClaimTrackingState',
          this.jobId
        )
      } else {
        this.$router.replace('/')
      }
      return
    }

    this.claimTrackingModelList.forEach((element) => {
      this.claimEmergencyDetail.push(element.claimEmergencyDetail)
    })
  }

  private async signalRJobTrackingRequest() {
    this.signalRHub.addHandler(
      'jobTrackingRequest',
      async (documentId: string, type: ClaimTrackingType) => {
        const result = await this.$store.dispatch(
          'insurerPortalModule/getJobLocation',
          documentId
        )

        let claimTrackingModel: ClaimTrackingModel | undefined
        switch (type) {
          case ClaimTrackingType.TrackClaimEmergencyDetail: {
            const claimEmergencyDetail: ClaimEmergencyDetail = Object.assign(
              new ClaimEmergencyDetail(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail &&
                c.claimEmergencyDetail.detailId ===
                  claimEmergencyDetail.detailId
            )
            if (!claimTrackingModel) {
              // some time it is possible claim request comes before claim emergency detail request
              claimTrackingModel = this.claimTrackingModelList.find(
                (c) =>
                  c.claimRequest.forEmergencyDetailId ===
                  claimEmergencyDetail.detailId
              )
            }
            if (claimTrackingModel) {
              claimTrackingModel.claimEmergencyDetail = claimEmergencyDetail
            } else {
              claimTrackingModel = new ClaimTrackingModel()
              claimTrackingModel.claimEmergencyDetail = claimEmergencyDetail
              this.claimTrackingModelList.push(claimTrackingModel)
            }
            break
          }
          case ClaimTrackingType.TrackClaimRequest: {
            const claimRequest: ClaimRequest = Object.assign(
              new ClaimRequest(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail &&
                c.claimEmergencyDetail.detailId ===
                  claimRequest.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.claimRequest = claimRequest
            } else {
              claimTrackingModel = new ClaimTrackingModel()
              claimTrackingModel.claimRequest = claimRequest
              this.claimTrackingModelList.push(claimTrackingModel)
            }
            break
          }
          case ClaimTrackingType.TrackCoverIncluded: {
            const coverIncluded: CoverIncluded = Object.assign(
              new CoverIncluded(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                coverIncluded.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.coverIncluded = coverIncluded
            }
            break
          }
          case ClaimTrackingType.TrackEngineerAssigned: {
            const engineerAssigned: EngineerAssigned = Object.assign(
              new EngineerAssigned(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerAssigned.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.engineerAssigned = engineerAssigned
            }
            break
          }
          case ClaimTrackingType.TrackEngineerOnTheWay: {
            if (!this.isCurrentTabIsActive) {
              return
            }
            const engineerOnTheWay: EngineerOnTheWay = Object.assign(
              new EngineerOnTheWay(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerOnTheWay.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              if (!claimTrackingModel.engineerOnTheWay) {
                this.engineerLocationPreviousLatitude =
                  engineerOnTheWay.latitude
                this.engineerLocationPreviousLongitude =
                  engineerOnTheWay.longitude
              } else {
                // take past lat long to show transaction
                this.engineerLocationPreviousLatitude =
                  claimTrackingModel.engineerOnTheWay.latitude
                this.engineerLocationPreviousLongitude =
                  claimTrackingModel.engineerOnTheWay.longitude
              }
              this.headingMagneticNorth = engineerOnTheWay.headingMagneticNorth
              claimTrackingModel.engineerOnTheWay = engineerOnTheWay
            }
            break
          }
          case ClaimTrackingType.TrackEngineerOnSite: {
            const engineerOnSite: EngineerOnSite = Object.assign(
              new EngineerOnSite(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerOnSite.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.engineerOnSite = engineerOnSite
            }
            break
          }
          case ClaimTrackingType.TrackClaimVisitComplete: {
            const claimVisitComplete: ClaimVisitComplete = Object.assign(
              new ClaimVisitComplete(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                claimVisitComplete.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.claimVisitComplete = claimVisitComplete
            }
            break
          }
        }
      }
    )

    // assign empty model on job abandoned
    this.signalRHub.addHandler('deleteTrackingDocuments', (visitId: string) => {
      const trackingModelIndex: number = this.claimTrackingModelList.findIndex(
        (model) =>
          model.engineerAssigned
            ? model.engineerAssigned.engineerVisitDetailId === visitId
            : false
      )
      if (trackingModelIndex !== -1) {
        const trackingModel = this.claimTrackingModelList[trackingModelIndex]
        // revert emergency tracking on abandoned
        trackingModel.engineerAssigned = null
        trackingModel.engineerOnTheWay = null
        trackingModel.engineerOnSite = null
        this.claimTrackingModelList.splice(trackingModelIndex, 1, trackingModel)
        // revert the progress bar and header text
        const trackingDetail = this.$refs
          .claimTrackingDetail as ClaimTrackingDetails[]
        const index = this.claimTrackingModelList.indexOf(trackingModel)
        trackingDetail[index].updatedClaimTrackingModel(trackingModel, true)
      }
    })

    // later on we will pass tracking ID over here
    this.signalRHub.connect(this.trackingId)
    await this.$store.dispatch(
      'generalModule/addUserToNotificationsForJobTracking'
    )
  }

  private mounted() {
    window.addEventListener(
      'beforeunload',
      () => {
        // send event updates to google analytics
        Shared.sendEventsInGoogleAnalytics(
          'Tracking Page',
          'TIME SPENT',
          this.localTimer
        )
      },
      false
    )
  }

  public showProgressBar(isEmergencyCompleted: boolean) {
    return !!isEmergencyCompleted
  }

  public get isUserVerified(): boolean {
    return this.$store.getters['claimTrackingModule/isUserVerifiedSuccess']
  }

  @Watch('isUserVerified')
  private async verificationChanged(verified: boolean) {
    if (verified && this.isTFAEnabled()) {
      await this.GetJobTrackingDocumentsWithToken()
      await this.signalRJobTrackingRequest()
    }
  }

  //Cannot be a getter - causing synchonisation issues
  public isTFAEnabled(): boolean {
    return !!this.$ld.variation('fnol-65-two-factor-auth-on-claim-tracking')
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get trackingId(): string {
    return this.$route.params.trackingId
  }
}
