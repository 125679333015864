import InsurerPortalModel from '@/models/InsurerPortalModel'
import EmergencyModel from '@/models/EmergencyModel'
import { IInsurerPortalState } from './interfaces/insurer-portal-state'

export default class InsurerPortalState implements IInsurerPortalState {
  public insurerPortalModel: InsurerPortalModel = new InsurerPortalModel()
  public emergencyDisplayList: EmergencyModel[] = []
  public emergencyTypes: EmergencyModel[] = []
  public acceptedEmergencies: number[] = []
}
