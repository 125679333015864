var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":"Customer address"}},[_c('div',{staticClass:"client-details"},[_c('div',{attrs:{"role":"form","aria-label":"Customer address form"}},[_c('v-container',{staticClass:"px-2",attrs:{"fluid":""}},[_c('h2',{staticClass:"title",style:({ color: _vm.clientTemplate.primaryColor })},[_vm._v(" Your Property ")]),_c('v-row',{staticClass:"pa-2 flex-wrap ma-0",style:({ background: _vm.clientTemplate.secondaryColor })},[(_vm.isShowDetailAddress)?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Address Line1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var inValid = ref.inValid;
return [_c('v-text-field',{ref:"addressLine1",staticClass:"required",attrs:{"label":"Address Line1","aria-required":"true","maxlength":"100","required":"","error-messages":errors,"error":inValid},model:{value:(_vm.addressLine1),callback:function ($$v) {_vm.addressLine1=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addressLine1"}})]}}],null,false,1260052743)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Address Line2","maxlength":"100"},model:{value:(_vm.addressLine2),callback:function ($$v) {_vm.addressLine2=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addressLine2"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"City","maxlength":"100"},model:{value:(_vm.addressLine3),callback:function ($$v) {_vm.addressLine3=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addressLine3"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"County","maxlength":"100"},model:{value:(_vm.addressLine4),callback:function ($$v) {_vm.addressLine4=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addressLine4"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var inValid = ref.inValid;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":"Postcode","aria-required":"true","required":"","maxlength":"20","error-messages":errors,"error":inValid},model:{value:(_vm.postcode),callback:function ($$v) {_vm.postcode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"postcode"}})]}}],null,false,2384324986)})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-0 pa-0 float-right",attrs:{"text":"","color":"primary","aria-label":"Add address manually"},on:{"click":function($event){return _vm.toggleActiveAddressSearch(true)}}},[_c('span',[_vm._v("Back")])])],1)]:[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Post Code","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var inValid = ref.inValid;
return [(!_vm.appInEditMode)?_c('v-autocomplete-custom',{ref:"searchAddressField",staticClass:"required customer-address-select",attrs:{"id":"customer-address-select","tabindex":"0","label":"Search Post Code","aria-required":"true","autofocus":"","loading":_vm.searchAddressRunning,"items":_vm.searchResult,"autocomplete":"off","search-input":_vm.searchAddress,"item-text":"Description","return-object":"","no-filter":"","menu-props":{ maxHeight: '250' },"aria-owns":"customer-address-select","aria-haspopup":"listbox","error":inValid,"error-messages":errors},on:{"update:searchInput":function($event){_vm.searchAddress=$event},"update:search-input":function($event){_vm.searchAddress=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"v-select__selection",attrs:{"id":"selected-address","aria-haspopup":"listbox","role":"button","aria-label":'Selected address ' + data.item.Description}},[_vm._v(" "+_vm._s(data.item.Description)+" ")])]}}],null,true),model:{value:(_vm.addressSearchModel),callback:function ($$v) {_vm.addressSearchModel=$$v},expression:"addressSearchModel"}}):_vm._e()]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-0 pa-0 float-right",attrs:{"text":"","color":"primary","aria-label":"Add address manually"},on:{"click":function($event){return _vm.toggleActiveAddressSearch(false)}}},[_c('span',[_vm._v(" Add Address Manually ")])])],1)]],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }