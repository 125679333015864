













































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Environment from '@/common/environment'
import EmergencyModel from '@/models/EmergencyModel'
import AddEmergency from '@/models/AddEmergency'
import DateTimePicker from '@/components/DateTimePicker.vue'
import moment from 'moment'
import EmergencyDetailModel from '@/models/EmergencyDetailModel'
import ClientTemplate from '@/models/client/client-template'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import EmergencyQA from '@/models/EmergencyQA'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import VSelectCustom from './custom-vuetify/VSelectCustom'

@Component({
  components: {
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    VSelectCustom,
  },
})
export default class EmergencySelection extends Vue {
  $refs!: {
    [k: string]: any
  }

  private hasMoreEmergency = false
  private maxFirstNoticedAtDate = ''
  private topEmergencyLength = 0

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  public get emergenciesQAs() {
    return this.insurerPortal.emergenciesQAs
  }

  private set emergenciesQAs(payload: EmergencyQA[]) {
    this.$store.dispatch('insurerPortalModule/setEmergenciesQAs', payload)
  }

  private get selectedEmergencyIds(): number[] {
    // need for validate function of ValidationProvider
    return this.emergencies.map((item) => item.emergencyTypeId)
  }

  @Watch('emergencies', { deep: true })
  private async revalidate() {
    await this.$refs.emergencyRef.validate()
  }

  @Watch('emergencyDisplayList')
  private updateEmergency() {
    this.topEmergencyLength = this.emergencyDisplayList.length
    this.hasMoreEmergency =
      this.topEmergencyLength === this.emergencyTypes.length
  }

  private getSelectedItem(description: string, emergency: EmergencyModel) {
    if (emergency && emergency.description) {
      return description === emergency.description
    }
    return false
  }

  private removeSelectedEmergency(emergency: AddEmergency) {
    this.getEmergenciesWithDetail.splice(
      this.getEmergenciesWithDetail.findIndex(
        (d: any) => d.emergencyTypeId === emergency.emergencyTypeId
      ),
      1
    )
  }

  private mounted() {
    this.maxFirstNoticedAtDate = moment().format(DateTimePicker.DATE_FORMAT)

    this.topEmergencyLength = this.emergencyDisplayList.length
    this.hasMoreEmergency =
      this.topEmergencyLength === this.emergencyTypes.length
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private clickMoreEmergencies() {
    this.hasMoreEmergency = true
    this.emergencyDisplayList = this.emergencyDisplayList.concat(
      this.emergencyTypes.filter((m) => {
        return !this.emergencyDisplayList.some((s) => {
          return m.id === s.id
        })
      })
    )
  }

  private get getEmergenciesWithDetail(): AddEmergency[] {
    return this.emergencies
  }
  private set getEmergenciesWithDetail(value: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', value)
  }

  private onEmergencySelection(
    emergency: EmergencyModel,
    validate: (e: any) => Promise<any>
  ): void {
    const selectedEmergency: AddEmergency = new AddEmergency()
    if (this.isEmergencySelected(emergency.id)) {
      this.getEmergenciesWithDetail.splice(
        this.getEmergenciesWithDetail.findIndex(
          (d: any) => d.emergencyTypeId === emergency.id
        ),
        1
      )
    } else {
      const defaultEmergencyDetail = new EmergencyDetailModel()
      defaultEmergencyDetail.description = 'Select Emergency Detail'
      ;(defaultEmergencyDetail as any).id = null
      defaultEmergencyDetail.tradeId = -1
      defaultEmergencyDetail.typeId = -1
      if (
        emergency &&
        emergency.emergencyDetails &&
        emergency.emergencyDetails.length > 0
      ) {
        emergency.emergencyDetails.unshift(defaultEmergencyDetail)
      } else {
        emergency.emergencyDetails = []
        emergency.emergencyDetails.push(defaultEmergencyDetail)
      }

      // Maintain list of emergencies in InsurerPortalModel
      selectedEmergency.emergencyTypeId = emergency.id
      selectedEmergency.emergencyTypeDesc = emergency.description
      selectedEmergency.fileURL = emergency.fileURL
      selectedEmergency.emergencyDetailDesc = defaultEmergencyDetail.description
      selectedEmergency.emergencyDetailId = defaultEmergencyDetail.id
      ;(selectedEmergency.emergencyDetail as any) = null
      this.getEmergenciesWithDetail &&
        this.getEmergenciesWithDetail.push(selectedEmergency)

      Vue.nextTick(() => {
        const emergencyDetailElement: HTMLElement | null =
          document.getElementById(`selected-emergency-${emergency.id}`)
        emergencyDetailElement?.scrollIntoView({ behavior: 'smooth' })
      })
    }

    this.updateInsurerPortalEmergenciesQAs(emergency.id)

    validate(this.selectedEmergencyIds)

    Vue.nextTick(() => {
      const currentEmergencyIndex =
        this.getEmergenciesWithDetail.indexOf(selectedEmergency)
      if (currentEmergencyIndex >= 0) {
        const commentRef: any = this.$refs['emergency' + currentEmergencyIndex]
        commentRef[0].blur()
      }
    })
  }

  private updateInsurerPortalEmergenciesQAs(emergencyId: number): void {
    if (this.emergenciesQAs.length > 0 && emergencyId) {
      const filteredEmergencyQAs = this.emergenciesQAs.filter(
        (e) => e.forEmergencyTypeId !== emergencyId
      )
      this.emergenciesQAs = filteredEmergencyQAs
    }
  }

  private getSelectedEmergencyDetail(item: any) {
    const emergency: EmergencyModel | undefined =
      this.emergencyDisplayList.find((e) => e.id === item.emergencyTypeId)
    if (emergency !== undefined) {
      return emergency.emergencyDetails
    }
  }

  private onChangeEmergencyDetail(item: any) {
    // Maintain list of emergencies in InsurerPortalModel
    Vue.nextTick(() => {
      const emergency: AddEmergency | undefined =
        this.getEmergenciesWithDetail.find(
          (e: any) => e.emergencyTypeId === item.emergencyTypeId
        )
      if (emergency !== undefined) {
        item.emergencyDetail =
          emergency.emergencyDetail?.id === null
            ? null
            : emergency.emergencyDetail
        if (item.emergencyDetail) {
          const detailItem: any = emergency.emergencyDetail
          emergency.emergencyDetailId = detailItem.id
          emergency.emergencyDetailDesc = detailItem.description
          emergency.tradeId = detailItem.tradeId
        }
      }
    })
  }

  private isEmergencySelected(id: number) {
    return this.selectedEmergencyIds.find((x: number) => x === id)
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private set emergencyTypes(emergencyList: EmergencyModel[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencyTypes', emergencyList)
  }

  private get emergencyTypes(): EmergencyModel[] {
    return this.$store.getters['insurerPortalModule/emergencyTypes']
  }

  private set emergencyDisplayList(emergencyList: EmergencyModel[]) {
    this.$store.dispatch(
      'insurerPortalModule/setEmergencyDisplayList',
      emergencyList
    )
  }

  private get emergencyDisplayList(): EmergencyModel[] {
    return this.$store.getters['insurerPortalModule/emergencyDisplayList']
  }

  private set emergencies(emergencies: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', emergencies)
  }

  private get emergencies(): AddEmergency[] {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
      .emergencies
  }
}
