// This is where we setup our vee validate config.
import { englishLocale } from '@/validation/locales/english-locale'
import { localize } from 'vee-validate'
import { addRules } from './validation-rules'

// To improve performance we only import the rules we need.
addRules()

// Where we set our custom error messages
localize({
  en: englishLocale,
})
