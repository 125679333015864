































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ClientDetail from '@/components/ClientDetail.vue'
import InsurerPortalModel from '../models/InsurerPortalModel'
import Shared from '../common/shared'
import RequestVerificationCodeModel from '../models/RequestVerificationCodeModel'
import ClientTemplate from '@/models/client/client-template'
import {
  ContactPreference as ContactPreferenceEnum,
  ResponseType,
} from '@/common/enums'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import VerifyPortalAccessUserModel from '@/models/VerifyPortalAccessUserModel'
import RecaptchaVerification from '@/components/RecaptchaVerification.vue'
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import ValidateVerificationCodeModel from '@/models/ValidateVerificationCodeModel'
import ResponseOfValidateVerificationCodeModel from '@/models/ResponseOfValidateVerificationCodeModel'
import Environment from '@/common/environment'

@Component({
  components: { ClientDetail, RecaptchaVerification },
})
export default class CustomerVerification extends Vue {
  private validateUserVerificationMessage = ''
  private verificationCodeValue = ''

  private regexSixDigit = new RegExp('\\d{6}')

  private showTimer = true
  private disableResendButton = false
  private isResendSuccessful = false
  private timer = 0
  private timeLimitToEnableResendButton = 60
  private timeLeftToEnableResendButton = 60
  private contactPreferenceEnum = ContactPreferenceEnum
  private localVerifyCustomer: VerifyPortalAccessUserModel | null = null

  $refs!: {
    recaptchaVerification: RecaptchaVerification
  }

  private get verificationCode(): string {
    return this.verificationCodeValue
  }

  private set verificationCode(value) {
    this.verificationCodeValue = value
    if (this.regexSixDigit.test(value)) {
      this.validateVerificationCode()
    }
  }

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['generalModule/navigation'].isUserVerifiedSuccess
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get termsAndServicesAccepted(): boolean {
    return this.$store.getters['cookieModule/termsAndServicesAccepted']
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  public get recaptchaToken() {
    return this.$store.getters['generalModule/recaptchaToken']
  }

  public get isRecaptchaValid(): boolean {
    const recaptchaResponse: RecaptchaResponse | null =
      this.$store.getters['generalModule/recaptchaResponse']
    return recaptchaResponse !== null && recaptchaResponse.success
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get highestVisitedStep(): number {
    return this.$store.getters['generalModule/navigation'].highestVisitedStep
  }

  @Watch('currentStep')
  public handleCustomerInforUpdate(_: any, oldValue: number) {
    if (
      this.highestVisitedStep < 4 &&
      oldValue === 2 &&
      this.isCustomerInforUpdated
    ) {
      if (this.isReCaptchaEnabled) {
        this.$refs.recaptchaVerification.verifyCustomer()
      } else {
        this.verifyCustomer()
      }
    }
  }

  private get isCustomerInforUpdated(): boolean {
    if (this.localVerifyCustomer) {
      if (
        this.insurerPortal.contactPreference ===
        this.contactPreferenceEnum.Email
      ) {
        return this.localVerifyCustomer.email !== this.insurerPortal.email
      } else {
        return (
          this.localVerifyCustomer.mobilePhone !==
          this.insurerPortal.mobilePhone
        )
      }
    }
    return false
  }

  private async verifyCustomer(): Promise<void> {
    try {
      const {
        clientTitle,
        clientForename,
        clientLastName,
        mobilePhone,
        email,
      } = this.insurerPortal

      const verifyCustomer: VerifyPortalAccessUserModel = {
        clientTitle,
        clientForename,
        clientLastName,
        mobilePhone,
        email,
        contactPreference: this.insurerPortal.contactPreference,
        termsAndConditionsAccepted: !this.clientTemplate.privacyPolicyText
          ? true
          : this.termsAndServicesAccepted,
        captchaResponse: this.recaptchaToken,
      }
      this.localVerifyCustomer = JSON.parse(JSON.stringify(verifyCustomer))
      await this.$store.dispatch(
        'generalModule/submitVerifyCustomer',
        verifyCustomer
      )
    } catch (err) {
      this.$store.dispatch(
        'generalModule/submitShowErrorSnackbar',
        new ErrorSnackBar('Something went wrong, please try again!'),
        { root: true }
      )
    }
  }

  private mounted() {
    if (this.appInEditMode) {
      this.verificationCode = '111111'
    }
    this.setTimerValue()
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private async resendVerificationCode() {
    this.disableResendButton = true
    this.isResendSuccessful = false
    this.validateUserVerificationMessage = ''
    const requestVerificationCodeModel: RequestVerificationCodeModel = {
      trackingId: this.getTrackingId,
    }
    var response = await this.$store.dispatch(
      'insurerPortalModule/resendVerificationCode',
      requestVerificationCodeModel
    )

    if (response) {
      this.disableResendButton = false
      this.validateUserVerificationMessage = 'Verification code resent!'
      this.isResendSuccessful = true
      this.verificationCode = ''
      this.showTimer = true
      this.setTimerValue()
    } else {
      this.showTimer = false
      this.validateUserVerificationMessage =
        'Something went wrong. Please try again!'
    }
  }

  private setTimerValue() {
    if (!this.isResendSuccessful && !this.disableResendButton) {
      this.isResendSuccessful = false
    }
    if (!this.isUserVerifiedSuccess) {
      // set timer
      this.showTimer = true
      this.startTimer()
    } else {
      this.showTimer = false
    }
  }

  private startTimer(): void {
    // reset the timer
    clearInterval(this.timer)
    this.timeLeftToEnableResendButton = this.timeLimitToEnableResendButton
    if (this.showTimer) {
      this.timer = setInterval(() => {
        if (this.timeLeftToEnableResendButton > 0) {
          this.timeLeftToEnableResendButton--
        } else {
          this.showTimer = false
        }
      }, 1000)
    }
  }

  private get getTimeLeft(): string {
    return Shared.getMinutesFromSecond(
      this.timeLeftToEnableResendButton,
      '0',
      2
    )
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  public get isReCaptchaEnabled(): boolean {
    return this.environment.isReCaptchaEnabled
  }

  public created() {
    if (!this.isReCaptchaEnabled) {
      this.verifyCustomer()
    }
  }
  public validateVerificationCode() {
    this.isLoading = true

    this.validateUserVerificationMessage = ''

    // set properties to verify user portal access
    const validateVerificationCodeModel: ValidateVerificationCodeModel = {
      trackingId: this.getTrackingId,
      clientTitle: this.insurerPortal.clientTitle,
      clientForename: this.insurerPortal.clientForename,
      clientLastName: this.insurerPortal.clientLastName,
      code: this.verificationCode,
    }

    this.$store
      .dispatch(
        'insurerPortalModule/submitVerificationCode',
        validateVerificationCodeModel
      )
      .then((response: ResponseOfValidateVerificationCodeModel | null) => {
        if (response && response.response === ResponseType.Verified) {
          /// success, go to next step
          this.validateUserVerificationMessage =
            'The code was verified Successfully'
          this.$store.dispatch('generalModule/setUserVerifiedStatus', true)
          this.$emit('userVerified')
        } else {
          this.validateUserVerificationMessage = response
            ? response.message
            : 'Something went wrong. Please try again!'
        }
      })

    this.isLoading = false
  }
}
