































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import moment, { Moment } from 'moment'
import Environment from '@/common/environment'
import ClaimTrackingModel from '@/models/ClaimTracking/ClaimTrackingModel'
import Shared from '@/common/shared'
import Map from '@/components/Map.vue'
import ClientTemplate from '@/models/client/client-template'

@Component({
  name: 'ClaimTrackingDetails',
  components: {
    Map,
  },
})
export default class ClaimTrackingDetails extends Vue {
  @Prop() public claimTrackingDetailsModel: ClaimTrackingModel
  @Prop() public totalClaimsCount: number
  @Prop() private selectedClaimIndex: number
  @Prop() public previousLatitude: number
  @Prop() public previousLongitude: number
  @Prop() public headingMagneticNorth: number
  @Prop() public showProgressBar: boolean
  public ready = false
  public currentClaimDetails: any = {}
  public trackingCompletionRatio = 100
  private numberOfSteps = 6
  private trackingCompletionRatioGap: number = 100 / this.numberOfSteps
  public claimTrackingDetailModel: ClaimTrackingModel = new ClaimTrackingModel()
  private showMap = false
  public customerAddressLatitude = 0
  public customerAddressLongitude = 0

  private isJobAbandoned = false
  private timerProgressBarHandle: number | null = null
  public isProgressBarLoaderVisible = false
  private intervalToShowLoader: number = 1000 * 30
  private intervalToHideLoader: number = 1000 * 3

  public get currentClaimIndex(): number {
    return this.selectedClaimIndex + 1
  }

  public get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get claimTrackingModel(): ClaimTrackingModel {
    return this.claimTrackingDetailModel
  }

  private set claimTrackingModel(value: ClaimTrackingModel) {
    this.claimTrackingDetailModel = value
  }

  public updatedClaimTrackingModel(
    value: ClaimTrackingModel,
    isJobAbandoned: boolean
  ) {
    this.isJobAbandoned = false
    if (isJobAbandoned) {
      this.isJobAbandoned = isJobAbandoned
    }
    this.claimTrackingDetailModel = new ClaimTrackingModel()
    this.claimTrackingModel = value
  }

  @Watch('selectedClaimIndex')
  private getSelectedClaimIndex() {
    // start timer to show update notification
    this.startTimerToShowProgressbar()
    return this.selectedClaimIndex
  }

  @Watch('totalClaimsCount')
  private getTotalClaimsCount() {
    return this.totalClaimsCount
  }

  @Watch('claimTrackingDetailModel')
  private claimTrackingDetailsModelBindings(newValue: ClaimTrackingModel) {
    this.claimTrackingModel = newValue
    this.updateProgressBarStatus(this.claimTrackingModel, true)
  }

  private created() {
    this.claimTrackingDetailModel = this.claimTrackingDetailsModel
  }

  private mounted() {
    this.ready = true
    this.updateProgressBarStatus(this.claimTrackingDetailModel, false)
    // start timer to show update notification
    if (this.showProgressBar) {
      this.resetInactivityTimer()
    }
  }

  private updateProgressBarStatus(
    claimTrackingModel: ClaimTrackingModel,
    isFromSignalR: boolean
  ) {
    let response
    // bind default value of loader
    if (claimTrackingModel) {
      if (claimTrackingModel.claimVisitComplete) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.claimVisitComplete
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 6)
        // stop timer if emergency is completed
        this.stopProgressBarTimer()
      } else if (claimTrackingModel.engineerOnSite) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerOnSite
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 5)
      } else if (claimTrackingModel.engineerOnTheWay) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerOnTheWay
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 4)
      } else if (claimTrackingModel.engineerAssigned) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerAssigned
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 3)
      } else if (claimTrackingModel.coverIncluded) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.coverIncluded
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 2)
      } else if (claimTrackingModel.claimRequest) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.claimRequest
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 1)
      } else {
        this.setProgressRatio(0)
      }
    }

    if (response && (!isFromSignalR || this.isJobAbandoned)) {
      this.showClaimDetails(response)
    }
  }

  public getETADate(date: Moment): string {
    if (date) {
      return Shared.getFormatedDate(
        moment(date),
        this.environment.dateTimeFormat
      )
    }
    return ''
  }

  public get jobId(): string {
    return this.claimTrackingDetailsModel.claimRequest.jobId
  }

  private setProgressRatio(remainingSteps: number) {
    this.trackingCompletionRatio = remainingSteps
  }

  private showClaimDetails(claimDetails: any) {
    if (claimDetails) {
      this.currentClaimDetails = claimDetails
      const date = this.getETADate(claimDetails.createdAt).split(' ')
      this.currentClaimDetails.createdAtDate = date[0]
      this.currentClaimDetails.createdAtTime = date[1]
    }
  }

  public get isMapCoordinatesExists(): boolean {
    if (
      this.claimTrackingDetailModel &&
      this.claimTrackingDetailModel.engineerOnTheWay &&
      this.claimTrackingDetailModel.engineerOnTheWay.latitude &&
      this.claimTrackingDetailModel.engineerOnTheWay.latitude !== 0 &&
      this.claimTrackingDetailModel.engineerOnTheWay.longitude &&
      this.claimTrackingDetailModel.engineerOnTheWay.longitude !== 0 &&
      !this.claimTrackingDetailModel.engineerOnSite &&
      !this.claimTrackingDetailModel.claimVisitComplete
    ) {
      this.openEngineerLocationMap()
      return true
    }
    return false
  }

  private async openEngineerLocationMap() {
    if (
      this.customerAddressLatitude !== 0 &&
      this.customerAddressLongitude !== 0
    ) {
      this.showMap = true
      return
    }

    const result = await this.$store.dispatch(
      'insurerPortalModule/getJobLocation'
    )
    if (result) {
      this.customerAddressLatitude = result.latitude
      this.customerAddressLongitude = result.longitude
      this.showMap = true
      return
    }
  }

  private startTimerToShowProgressbar() {
    if (this.showProgressBar) {
      this.resetInactivityTimer()
    } else {
      this.stopProgressBarTimer()
    }
  }

  private resetInactivityTimer(): void {
    this.stopProgressBarTimer()
    this.timerProgressBarHandle = setInterval(
      this.showProgressBarLoader,
      this.intervalToShowLoader
    )
  }

  private showProgressBarLoader() {
    this.isProgressBarLoaderVisible = true
    setTimeout(() => {
      this.isProgressBarLoaderVisible = false
    }, this.intervalToHideLoader)
  }

  private stopProgressBarTimer() {
    if (this.timerProgressBarHandle) {
      clearTimeout(this.timerProgressBarHandle)
      this.timerProgressBarHandle = null
    }
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
