








































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Shared from '@/common/shared'
import Environment from '@/common/environment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

enum Tab {
  Date,
  Time,
}

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class DateTimePicker extends Vue {
  public static DATE_FORMAT = DATE_FORMAT
  public isValidDate = false

  private DateTab: Tab = Tab.Date
  private TimeTab: Tab = Tab.Time
  private controlDate: moment.Moment | null | undefined = null
  private initialValue: moment.Moment | null | undefined = null

  @Prop() private dateTime: moment.Moment
  @Prop() private placeHolderText: string
  @Prop() private minDate: string
  @Prop() private maxDate: string
  @Prop() private minTime: string
  @Prop() private maxTime: string
  @Prop() private displayFormat: string
  @Prop() private isStaticLocation: boolean
  @Prop() private isValidationRequired: boolean
  @Prop() private disabled: boolean
  @Prop() private isCurrentTime: boolean
  @Prop() private initialDateTimeControlOptionMsg: string
  @Prop({ default: -1 }) private index: number
  private globalDateTimeFormat: string = this.environment.dateTimeFormat
  private newDate: any = ''

  private showControl = false
  private dateModel = ''
  private timeModel = '00:00'
  private selectedTab: Tab = Tab.Date

  private pauseEmit = true
  private showControlOption = true

  private mounted() {
    this.pauseEmit = true
    this.setDateTimeModelsFromValue(this.dateTime)
    this.setControlDate()
    this.pauseEmit = false
  }

  @Watch('dateTime')
  private valueChanged(newValue: moment.Moment | null | undefined) {
    this.pauseEmit = true
    this.setDateTimeModelsFromValue(newValue)
    this.setControlDate()
    this.pauseEmit = false
    if (this.dateTime) {
      this.showControlOption = false
    }
  }

  @Watch('dateModel')
  private dateChanged() {
    this.setControlDate()
    this.selectedTab = Tab.Time
  }

  @Watch('timeModel')
  private timeChanged() {
    this.setControlDate()
  }

  @Watch('showControl')
  private controlVisiblityChanged(showingControl: boolean) {
    if (showingControl) {
      this.selectedTab = Tab.Date
      this.initialValue = this.dateTime
    }
  }

  private setControlDate(): void {
    if (this.dateModel && this.timeModel) {
      const val = this.dateModel + ' ' + this.timeModel
      this.controlDate = moment(val, DATE_TIME_FORMAT)
      this.newDate = moment(val, DATE_TIME_FORMAT)
      this.newDate = Shared.getFormatedDate(
        moment(this.newDate),
        this.environment.dateTimeFormat
      )
    } else {
      this.controlDate = undefined
    }
    if (!this.pauseEmit) {
      this.emitValueEvent(this.controlDate)
    }
  }

  private emitValueEvent(value: moment.Moment | null | undefined) {
    this.$emit('update:dateTime', value)
  }

  private setDateTimeModelsFromValue(
    newValue: moment.Moment | null | undefined
  ): void {
    if (!moment.isMoment(newValue) || !newValue.isValid()) {
      this.dateModel = ''
      this.timeModel = '00:00'
      return
    }

    const newDate: moment.Moment = moment(
      newValue.format(DATE_FORMAT),
      DATE_FORMAT
    )
    const newTime: moment.Moment = moment(
      newValue.format(TIME_FORMAT),
      TIME_FORMAT
    )

    if (this.minDate) {
      if (moment(this.minDate, DATE_FORMAT).isAfter(newDate)) {
        return
      }
    }
    if (this.maxDate) {
      if (moment(this.maxDate, DATE_FORMAT).isBefore(newDate)) {
        return
      }
    }
    if (this.minTime) {
      if (moment(this.minTime, TIME_FORMAT).isAfter(newTime)) {
        return
      }
    }
    if (this.maxTime) {
      if (moment(this.maxTime, TIME_FORMAT).isBefore(newTime)) {
        return
      }
    }

    const ndm: string = newValue.format(DATE_FORMAT)
    if (ndm !== this.dateModel) {
      this.dateModel = ndm
    }

    const ntm: string = newValue.format(TIME_FORMAT)
    if (ntm !== this.timeModel) {
      this.timeModel = ntm
    }
  }

  private get defaultMaxTime() {
    const currentMomentDate = moment()
    return currentMomentDate.format(DATE_FORMAT) === this.dateModel
      ? currentMomentDate.format(TIME_FORMAT)
      : undefined
  }

  private get selectedValueForDisplay(): string {
    if (moment.isMoment(this.dateTime) && this.dateTime.isValid()) {
      // return formatted date as per set in Environment json
      const formattedDate: string = this.displayFormat
        ? Shared.getFormatedDate(moment(this.dateTime), this.displayFormat)
        : Shared.getFormatedDate(
            moment(this.dateTime),
            this.environment.dateTimeFormat
          )
      return formattedDate
    } else {
      if (this.isValidationRequired !== true) {
        return '---- -- -- --:--'
      } else {
        return ''
      }
    }
  }

  private get yearName(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('MMMM YYYY')
    }
    return '-- ----'
  }

  private get dayName(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('dddd')
    }
    return '--'
  }

  private get dayValue(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('DD')
    }
    return '--'
  }

  private get timeValue(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('HH:mm')
    }
    return '--:--'
  }

  private get getDateTimeFormatMaskValue(): string {
    return this.globalDateTimeFormat.replace(/[a-zA-Z]/g, '#')
  }

  private onClickDate() {
    this.selectedTab = Tab.Date
  }

  private onClickTime() {
    this.selectedTab = Tab.Time
  }

  private onClickOk() {
    this.isValidDate = true
    this.showControl = false
  }

  private onClickCancel() {
    // user cancelled, reset dateTime back to value given on display
    this.emitValueEvent(this.initialValue)
    this.showControl = false
  }

  // Click present time icon to set current value
  private OnClickPresentTime() {
    // close current date picker control
    this.showControl = false
    this.emitValueEvent(moment(new Date()))
    this.isValidDate = true
    this.setFocusToDateTimeInput()
  }

  private setFocusToDateTimeInput() {
    this.showControlOption = false
    setTimeout(() => {
      const inputElement: any = this.$refs.dateTimeInput as any
      if (inputElement) {
        inputElement.focus()
      }
    }, 0)
  }

  private clickAnyDate() {
    this.dateChanged()
  }

  private validateDate(dateString: any) {
    this.isValidDate = moment(
      dateString,
      this.environment.dateTimeFormat,
      true
    ).isValid()
    if (
      this.isValidDate &&
      (moment(dateString, this.environment.dateTimeFormat).isAfter(
        this.dateTime
      ) ||
        moment(this.maxDate, DATE_FORMAT).isBefore(this.dateTime))
    ) {
      this.controlDate = moment(dateString, this.environment.dateTimeFormat)
      this.setDateTimeModelsFromValue(this.controlDate)
    } else {
      this.controlDate = undefined
    }
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }
}
