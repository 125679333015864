import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import EmergencyModel from '@/models/EmergencyModel'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import InsurerPortalState from '../states/insurer-portal-state'
import { IInsurerPortalState } from '../states/interfaces/insurer-portal-state'
import emergencyQuestionGeneratorMutations from '../mutations/InsurerPortal/emergency-question-generator.mutation'
import emergencyQuestionGeneratorActions from '../actions/InsurerPortal/emergency-question-generator.action'
import customerAddressMutation from '../mutations/InsurerPortal/customer-address.mutation'
import customerAddressAction from '../actions/InsurerPortal/customer-address.action'
import policyControllerAddressAction from '../actions/InsurerPortal/policyholder-controller.action'
import jobControllerAddressAction from '../actions/InsurerPortal/job-controller.action'
import ValidateVerificationCodeModel from '@/models/ValidateVerificationCodeModel'
import SessionController from '@/api/sessionController'
import RequestVerificationCodeModel from '@/models/RequestVerificationCodeModel'
import { ContactPreference } from '@/common/enums'

export const insurerPortalState: IInsurerPortalState = new InsurerPortalState()

export const getters: GetterTree<IInsurerPortalState, any> = {
  insurerPortalModel: (state): InsurerPortalModel => state.insurerPortalModel,
  emergencyDisplayList: (state): EmergencyModel[] => state.emergencyDisplayList,
  emergencyTypes: (state): EmergencyModel[] => state.emergencyTypes,
  acceptedEmergencies: (state): number[] => state.acceptedEmergencies,
}

export const mutations: MutationTree<IInsurerPortalState> = {
  ...customerAddressMutation,
  ...emergencyQuestionGeneratorMutations,
  setEmergencyDisplayList: (
    state: IInsurerPortalState,
    emergencyDisplayList: EmergencyModel[]
  ) => {
    state.emergencyDisplayList = emergencyDisplayList
  },
  setEmergencyTypes: (
    state: IInsurerPortalState,
    emergencyTypes: EmergencyModel[]
  ) => {
    state.emergencyTypes = emergencyTypes
  },
  setContactPreference: (
    state: IInsurerPortalState,
    contactPreference: ContactPreference | null
  ) => {
    state.insurerPortalModel.contactPreference = contactPreference
  },
  setPhoneNumber: (state: IInsurerPortalState, mobilePhone: string) => {
    state.insurerPortalModel.mobilePhone = mobilePhone
  },
  setAcceptedEmergencies: (state: IInsurerPortalState, value: number[]) => {
    state.acceptedEmergencies = value
  },
  setCustomerAvailability: (
    state: IInsurerPortalState,
    customerAvailability: CustomerAvailabilityModel
  ) => {
    state.insurerPortalModel.customerAvailability = customerAvailability
  },
}

export const actions: ActionTree<IInsurerPortalState, any> = {
  ...customerAddressAction,
  ...emergencyQuestionGeneratorActions,
  ...policyControllerAddressAction,
  ...jobControllerAddressAction,
  setEmergencyDisplayList({ commit }, emergencyList: EmergencyModel[]) {
    commit('setEmergencyDisplayList', emergencyList)
  },
  setEmergencyTypes({ commit }, emergencyList: EmergencyModel[]) {
    commit('setEmergencyTypes', emergencyList)
  },
  submitContactPreference(
    { commit },
    contactPreference: ContactPreference | null
  ) {
    commit('setContactPreference', contactPreference)
  },
  setPhoneNumber({ commit }, mobilePhone: string) {
    commit('setPhoneNumber', mobilePhone)
  },
  setAcceptedEmergencies({ commit }, value: number[]) {
    commit('setAcceptedEmergencies', value)
  },
  setCustomerAvailability(
    { commit },
    customerAvailability: CustomerAvailabilityModel
  ) {
    commit('setCustomerAvailability', customerAvailability)
  },
  async submitVerificationCode(
    _,
    verificationModel: ValidateVerificationCodeModel
  ) {
    return await SessionController.ValidateVerificationCode(verificationModel)
  },
  async resendVerificationCode(
    _,
    requestVerificationCodeModel: RequestVerificationCodeModel
  ) {
    return await SessionController.ReSendVerificationCode(
      requestVerificationCodeModel
    )
  },
}

export const insurerPortalModule: Module<IInsurerPortalState, any> = {
  state: insurerPortalState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
