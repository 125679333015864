import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { MutationTree } from 'vuex'

const setter =
  <K extends keyof IInsurerPortalState['insurerPortalModel']>(property: K) =>
  <T extends IInsurerPortalState['insurerPortalModel'][K]>(
    state: IInsurerPortalState,
    value: T
  ): void => {
    state.insurerPortalModel[property] = value
  }

const mutations: MutationTree<IInsurerPortalState> = {
  setVulnerabilityQA: setter('vulnerabilityQA'),
  setAccessNoteQA: setter('accessNoteQA'),
  setHealthAndSafetyQAs: setter('healthAndSafetyQAs'),
  setEmergenciesQAs: setter('emergenciesQAs'),
  setEmergencies: setter('emergencies'),

  setPolicyScheduleId: setter('policyScheduleId'),

  setPolicyName: setter('policyName'),
  setPolicyNumber: setter('policyNumber'),
  setIsPolicyCovered: setter('isPolicyCovered'),
  setContactPreference: setter('contactPreference'),
}

export default mutations
