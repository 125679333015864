


























































import { Component, Vue } from 'vue-property-decorator'
import ClientTemplate from '@/models/client/client-template'
import Shared from '@/common/shared'

@Component({
  name: 'PrivacyPolicyModal',
})
export default class PrivacyPolicyModal extends Vue {
  private sanitizeHTML = Shared.sanitizeHTML

  private mounted() {
    const privacyPolicyElement = document.querySelector(
      '.privacy-policy'
    ) as any
    if (privacyPolicyElement) {
      const linkElements = privacyPolicyElement.querySelectorAll(
        'a'
      ) as HTMLElement[]
      if (linkElements && linkElements.length > 0) {
        linkElements.forEach((link) => {
          link.style.color = this.clientTemplate.complementaryColor
        })
      }
    }
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get showPrivacyPolicyDetails(): boolean {
    return this.$store.getters['cookieModule/showPrivacyPolicyDetails']
  }

  private hidePrivacyPolicyDetails(): void {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', false)
  }

  private acceptTermsAndServices(): void {
    this.$store.dispatch('cookieModule/acceptTermsAndServices', true)
    this.hidePrivacyPolicyDetails()
  }
}
