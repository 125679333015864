/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VMenu, VSelect, VTextField } from 'vuetify/lib/components'
import { VNode } from 'vue'
import Component from 'vue-class-component'

@Component
export default class CustomVSelect extends VSelect {
  mounted() {
    const inputSlot = this.$refs['input-slot'] as any
    inputSlot.setAttribute('role', 'region')
  }
  genList(): VNode {
    const { genListWithSlot, staticList, computedOwns } = this as any
    // If there's no slots, we can use a cached VNode to improve performance
    if (
      this.$slots['no-data'] ||
      this.$slots['prepend-item'] ||
      this.$slots['append-item']
    ) {
      return genListWithSlot()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      staticList.data!.attrs!.title = computedOwns
      return staticList
    }
  }
  genMenu(): VNode {
    const { $_menuProps, attach, onScroll } = this as any
    const props = $_menuProps as any
    props.activator = this.$refs['input-slot']

    // Attach to root el so that
    // menu covers prepend/append icons
    if (
      // TODO: make this a computed property or helper or something
      attach === '' || // If used as a boolean prop (<v-menu attach>)
      attach === true || // If bound to a boolean (<v-menu :attach="true">)
      attach === 'attach' // If bound as boolean prop in pug (v-menu(attach))
    ) {
      props.attach = this.$el
    } else {
      props.attach = attach
    }

    return this.$createElement(
      VMenu,
      {
        attrs: { role: 'contentinfo' },
        props,
        on: {
          input: (val: boolean) => {
            // use this as any due to those property define in data() of VSelect component, object destructuring may causes issue
            ;(this as any).isMenuActive = val
            ;(this as any).isFocused = val
          },
          scroll: onScroll,
        },
        ref: 'menu',
      },
      [this.genList()]
    )
  }
  genInputSlot(): VNode {
    const { computedOwns, isMenuActive } = this as any
    const render = (VTextField as any).options.methods.genInputSlot.call(this)

    render.data!.attrs = {
      ...render.data!.attrs,
      role: 'region',
      'aria-haspopup': 'listbox',
      'aria-label': computedOwns,
      'aria-expanded': String(isMenuActive),
      'aria-owns': computedOwns,
    }

    return render
  }
}
