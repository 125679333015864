import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { IClientState } from '../states/interfaces/client-state'
import ClientState from '../states/client-state'
import ClientTemplate from '@/models/client/client-template'
import { onClientTemplateChanged } from '@/plugins/datadog'

export const clientState: IClientState = new ClientState()

export const getters: GetterTree<IClientState, any> = {
  clientTemplate: (state): ClientTemplate => state.clientTemplate,
  portalId: (state): string => state.clientTemplate.subDomain,
  isPreviewMode: (state): boolean => state.isPreviewMode,
}

export const mutations: MutationTree<IClientState> = {
  setClientTemplate: (state, clientTemplate: ClientTemplate) => {
    state.clientTemplate = clientTemplate
    onClientTemplateChanged(clientTemplate)
  },
  setIsPreviewMode: (state, isPreviewMode: boolean) => {
    state.isPreviewMode = isPreviewMode
  },
  setTemplateSetting: (state, setting: { name: string; value: any }) => {
    const key = setting.name as keyof ClientTemplate
    ;(state.clientTemplate[key] as any) = setting.value
  },
}

export const actions: ActionTree<IClientState, any> = {
  async submitClientTemplate({ commit }, clientTemplate: ClientTemplate) {
    commit('setClientTemplate', clientTemplate)
  },
  async submitIsPreviewMode({ commit }, isPreviewMode: boolean) {
    commit('setIsPreviewMode', isPreviewMode)
  },
  async submitUpdateTemplateSetting(
    { commit },
    setting: { name: string; value: any }
  ) {
    commit('setTemplateSetting', setting)
  },
}

export const clientModule: Module<IClientState, any> = {
  state: clientState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
