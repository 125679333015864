







































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Shared from '../common/shared'
import RequestVerificationCodeModel from '../models/RequestVerificationCodeModel'
import ClientTemplate from '@/models/client/client-template'
import { ResponseType } from '@/common/enums'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import VerifyClaimTrackingUserModel from '@/models/VerifyClaimTrackingUserModel'
import ValidateClaimTrackingVerificationCodeModel from '@/models/ValidateClaimTrackingVerificationCodeModel'
import ResponseOfValidateVerificationCodeModel from '@/models/ResponseOfValidateVerificationCodeModel'
import SessionController from '@/api/sessionController'

@Component({})
export default class CustomerVerification extends Vue {
  @Prop() private jobId: string
  public termsAndServiceCheckbox = false

  public validateUserVerificationMessage = ''
  private verificationCodeValue = ''

  private regexSixDigit = new RegExp('\\d{6}')

  public showTimer = true
  public disableResendButton = false
  private isResendSuccessful = false
  private timer = 0
  private timeLimitToEnableResendButton = 60
  private timerStartTime: number
  private secondsRemaining = 59
  public useNominatedContact = false
  public contactPreferenceSet = false

  public setContactPreference() {
    this.contactPreferenceSet = true

    this.verifyCustomer()
    this.setTimerValue()
  }

  public get verificationCode(): string {
    return this.verificationCodeValue
  }

  private set verificationCode(value) {
    this.verificationCodeValue = value
    if (this.regexSixDigit.test(value)) {
      this.validateVerificationCode()
    }
  }

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['claimTrackingModule/isUserVerifiedSuccess']
  }

  public get termsAndServicesAccepted(): boolean {
    return this.$store.getters['claimTrackingModule/termsAndServicesAccepted']
  }

  @Watch('termsAndServiceCheckbox')
  private updateTermsAndServiceAccepted() {
    this.$store.dispatch(
      'claimTrackingModule/updateTermsAndServices',
      this.termsAndServiceCheckbox
    )
  }

  public showPrivacyPolicyDetailsDialog() {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  private async verifyCustomer(): Promise<void> {
    try {
      const verifyCustomer: VerifyClaimTrackingUserModel = {
        jobId: this.jobId,
        termsAndConditionsAccepted:
          this.$store.getters['claimTrackingModule/termsAndServicesAccepted'],
        useNominatedContact: this.useNominatedContact,
      }

      SessionController.verifyCustomerForClaimTracking(verifyCustomer).then(
        (result) => {
          if (result && result.trackingId) {
            this.$store.dispatch(
              'claimTrackingModule/updateTrackingId',
              result.trackingId
            )
          }
        }
      )
    } catch (err) {
      this.$store.dispatch(
        'generalModule/submitShowErrorSnackbar',
        new ErrorSnackBar('Something went wrong, please try again!'),
        { root: true }
      )
    }
  }

  private mounted() {
    const storedJobId = this.$store.getters['claimTrackingModule/jobId']
    if (storedJobId !== this.jobId) {
      this.$store.dispatch(
        'claimTrackingModule/resetClaimTrackingState',
        this.jobId
      )
    }
  }

  private get trackingId() {
    return this.$store.getters['claimTrackingModule/trackingId']
  }

  public async resendVerificationCode() {
    this.disableResendButton = true
    this.isResendSuccessful = false
    this.validateUserVerificationMessage = ''
    const requestVerificationCodeModel: RequestVerificationCodeModel = {
      trackingId: this.trackingId,
    }
    var response = await SessionController.ReSendVerificationCode(
      requestVerificationCodeModel
    )

    if (response) {
      this.disableResendButton = false
      this.validateUserVerificationMessage = 'Verification code resent!'
      this.isResendSuccessful = true
      this.verificationCode = ''
      this.setTimerValue()
    } else {
      this.showTimer = false
      this.validateUserVerificationMessage =
        'Something went wrong. Please try again!'
    }
  }

  private setTimerValue() {
    if (!this.isResendSuccessful && !this.disableResendButton) {
      this.isResendSuccessful = false
    }
    if (!this.isUserVerifiedSuccess) {
      this.startTimer()
    } else {
      this.showTimer = false
    }
  }

  private startTimer(): void {
    clearInterval(this.timer)
    this.timerStartTime = Date.now()
    this.showTimer = true
    this.timer = setInterval(() => {
      const secondsElapsed = (Date.now() - this.timerStartTime) / 1000
      this.secondsRemaining = Math.max(
        0,
        Math.floor(this.timeLimitToEnableResendButton - secondsElapsed)
      )
      if (this.secondsRemaining <= 0) {
        clearInterval(this.timer)
        this.showTimer = false
      }
    }, 1000)
  }

  public get getTimeLeft(): string {
    return Shared.getMinutesFromSecond(this.secondsRemaining, '0', 2)
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  public validateVerificationCode() {
    this.validateUserVerificationMessage = ''

    // set properties to verify user portal access
    const validateVerificationCodeModel: ValidateClaimTrackingVerificationCodeModel =
      {
        trackingId: this.trackingId,
        jobId: this.jobId,
        code: this.verificationCode,
      }

    SessionController.ValidateClaimTrackingVerificationCode(
      validateVerificationCodeModel
    ).then((response: ResponseOfValidateVerificationCodeModel | null) => {
      if (response && response.response === ResponseType.Verified) {
        /// success, go to next step
        this.validateUserVerificationMessage =
          'The code was verified Successfully'
        this.$store.dispatch('claimTrackingModule/setUserVerifiedStatus', true)
        this.$store.dispatch('claimTrackingModule/setToken', response.message)
      } else {
        this.validateUserVerificationMessage = response
          ? response.message
          : 'Something went wrong. Please try again!'
      }
    })
  }
}
