import { PhoneNumberUtil } from 'google-libphonenumber'
const util: any = PhoneNumberUtil.getInstance()
export const validPhoneNumber: any = {
  validate(value: any, args: any): any {
    const phoneNumberObject: any = util.parseAndKeepRawInput(value, args.code)
    if (util.isValidNumber(phoneNumberObject)) {
      return true
    }
    return false
  },
  params: ['code'],
  message: 'Invalid phone number',
}
export const validCountry: any = {
  validate(value: any, args: any): any {
    const countries: any[] = [
      { name: 'Great Britain', cc: '+44', code: 'gb' },
      { name: 'Ireland', cc: '+353', code: 'ie' },
      { name: 'International', code: 'eu' },
    ]
    return countries.findIndex((d) => d.code === args.code) !== -1
  },
  params: ['code'],
  message: 'Invalid country',
}

export const possiblePhoneNumber: any = {
  validate(value: any, args: any): any {
    try {
      const phoneNumberObject: any = util.parseAndKeepRawInput(value, args.code)
      return util.isPossibleNumber(phoneNumberObject)
    } catch (error) {
      return false
    }
  },
  params: ['code'],
  message: 'Invalid length',
}
