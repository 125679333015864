import { Component } from 'vue-property-decorator'
import { VAutocomplete, VSelect, VMenu } from 'vuetify/lib'
import { VNode } from 'vue'

@Component({
  computed: {
    listData() {
      const {
        noFilter,
        isSearching,
        filteredItems,
        internalSearch,
        virtualizedItems,
      } = this as any
      const data = (VSelect as any).options.computed.listData.call(this) as any
      const attrs = data.attrs

      data.props = {
        ...data.props,
        items: virtualizedItems,
        noFilter: noFilter || !isSearching || !filteredItems.length,
        searchInput: internalSearch,
      }

      return { ...data, attrs: { ...attrs, 'aria-label': 'Select' } }
    },
  },
})
export default class VAutocompleteCustom extends VAutocomplete {
  genMenu(): VNode {
    const {
      $_menuProps,
      $refs,
      attach,
      $el,
      $createElement,
      onScroll,
      genList,
    } = this as any
    const props = $_menuProps as any
    props.activator = $refs['input-slot']

    // Attach to root el so that
    // menu covers prepend/append icons
    if (
      // TODO: make this a computed property or helper or something
      attach === '' || // If used as a boolean prop (<v-menu attach>)
      attach === true || // If bound to a boolean (<v-menu :attach="true">)
      attach === 'attach' // If bound as boolean prop in pug (v-menu(attach))
    ) {
      props.attach = $el
    } else {
      props.attach = attach
    }

    return $createElement(
      VMenu,
      {
        attrs: { role: 'region' },
        props,
        on: {
          input: (val: boolean) => {
            ;(this as any).isMenuActive = val
            ;(this as any).isFocused = val
          },
          scroll: onScroll,
        },
        ref: 'menu',
      },
      [genList()]
    )
  }
}
