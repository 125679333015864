import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import InsurerPortal from '@/views/InsurerPortal.vue'
import QuestionModePortal from '@/views/QuestionModePortal.vue'
import ClaimTracking from '@/views/ClaimTracking.vue'
import UploadInvoiceCAT from '@/components/UploadInvoiceCAT.vue'
import CookieNotice from '@/views/CookieNotice.vue'

Vue.use(Router)

declare let reloadOTBanner: any
declare let clearDup: any

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:clientName?/uploadInvoice/:trackingId/:recordId',
      name: 'brandedUploadInvoice',
      component: UploadInvoiceCAT,
      props: true,
    },
    {
      path: '/:clientName?/claimtracking/:trackingId',
      name: 'claimtracking',
      component: ClaimTracking,
      props: true,
    },
    {
      path: '/:clientName?/(preview/)?cookienotice',
      name: 'cookienotice',
      component: CookieNotice,
      props: true,
    },
    {
      path: '/:clientName?/questionmode/:trackingId',
      name: 'questionMode',
      component: QuestionModePortal,
      props: true,
    },
    {
      path: '/:clientName?/preview',
      name: 'previewMode',
      component: InsurerPortal,
      props: true,
    },
    {
      path: '/:clientName?',
      name: 'insurerportal',
      component: InsurerPortal,
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFound,
    },
  ],
})

router.afterEach((to) => {
  const store = router.app.$store
  if (store && !store.getters['cookieModule/useDefaultCookieBanner']) {
    reloadOTBanner()
    if (to.name === 'cookienotice') {
      clearDup()
    }
  }
})

export default router
