import { ImageType } from '@/common/enums'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import ImageBase from '@/models/image/image-base'
import IImageBase from '@/models/image/interfaces/image-base'
import { imageService } from '@/services/image-service'

import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import ImageState from '../states/image-state'
import { IImageState } from '../states/interfaces/image-state'

export const imageState: IImageState = new ImageState()

export const getters: GetterTree<IImageState, any> = {
  imagesToUpload: (state) => state.imagesToUpload,
  uploadedImages: (state) => state.uploadedImages,
  isImageUploading: (state): boolean => state.isImageUploading,
  failedUploaded: (state) => state.failedUploads,
}

export const mutations: MutationTree<IImageState> = {
  setImageToUpload: (state: IImageState, imageToUpload: ImageBase) => {
    state.imagesToUpload.push(imageToUpload)
  },
  setRemovePreviewImage: (state: IImageState, image: ImageBase) => {
    const index = state.imagesToUpload.indexOf(image)
    state.imagesToUpload.splice(index, 1)
  },
  setRemoveUploadedImage: (state: IImageState, imageToDelete: ImageBase) => {
    const index = state.uploadedImages.indexOf(imageToDelete)
    state.uploadedImages.splice(index, 1)
  },
  setUploadedImage: (state: IImageState, uploadedmage: ImageBase) => {
    const index = state.imagesToUpload.indexOf(uploadedmage)
    if (index > -1) {
      state.imagesToUpload.splice(index, 1)
    }
    state.uploadedImages.push(uploadedmage)
  },
  setFailedUpload: (state: IImageState, failedUpload: ImageBase) => {
    state.failedUploads.push(failedUpload)
  },
  resetFailedUploads: (state: IImageState, imageType: ImageType) => {
    state.failedUploads = state.failedUploads.filter(
      (x) => x.imageType !== imageType
    )
  },
}

export const actions: ActionTree<IImageState, any> = {
  async submitImageToUpload({ commit }, imageToUpload: ImageBase) {
    commit('setImageToUpload', imageToUpload)
  },
  async submitPreviewImageToRemove({ commit }, image: ImageBase) {
    commit('setRemovePreviewImage', image)
  },
  async submitUploadedImageToRemove(
    { dispatch, commit },
    imageToDelete: ImageBase
  ) {
    await imageService
      .deleteImage(imageToDelete)
      .then((success: boolean) => {
        if (success) {
          commit('setRemoveUploadedImage', imageToDelete)
        }
      })
      .catch(() => {
        const snackbar = new ErrorSnackBar(
          `Failed to delete image - ${imageToDelete.fileName}`
        )
        dispatch('generalModule/submitShowErrorSnackbar', snackbar, {
          root: true,
        })
      })
  },
  async submitUploadImages({ dispatch, commit, state }, imageType: ImageType) {
    commit('resetFailedUploads', imageType)
    const images: ImageBase[] = state.imagesToUpload.filter(
      (x) => x.imageType === imageType
    )
    images.forEach(async (image) => {
      await imageService
        .uploadImage(image)
        .then((imageId: string | null) => {
          image.id = imageId
          imageId
            ? commit('setUploadedImage', image)
            : commit('setFailedUpload', image)
        })
        .catch(() => {
          commit('setFailedUpload', image)
          const snackbar = new ErrorSnackBar(
            `Failed to upload image - ${image.fileName}`
          )
          dispatch('generalModule/submitShowErrorSnackbar', snackbar, {
            root: true,
          })
        })
    })
  },
  async submitRetrieveUploadedImages(
    { dispatch, commit },
    imageType: ImageType
  ) {
    await imageService
      .retrieveUploadedImages(imageType)
      .then((images: IImageBase[]) => {
        images.forEach((image) => commit('setUploadedImage', image))
      })
      .catch(() => {
        const snackbar = new ErrorSnackBar(
          'Failed to retrieve previously uploaded images'
        )
        dispatch('generalModule/submitShowErrorSnackbar', snackbar, {
          root: true,
        })
      })
  },
  async submitAuthoriseCustomerToUploadReceipt({ dispatch }, recordId: string) {
    imageService
      .authoriseCustomerToUploadReceipt(recordId)
      .then((isValid: boolean) => {
        return isValid
      })
      .catch(() => {
        const snackbar = new ErrorSnackBar(
          'Failed to authenticate the access token'
        )
        dispatch('generalModule/submitShowErrorSnackbar', snackbar, {
          root: true,
        })
        return false
      })
  },
}

export const imageModule: Module<IImageState, any> = {
  state: imageState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
