import { ImageType } from '@/common/enums'
import IImageBase from './interfaces/image-base'

export default class ImageBase implements IImageBase {
  public id: string | null = null
  public imageType: ImageType = ImageType.NotSet
  public imageFile: File
  public previewUrl = ''
  public fileName = ''
}
