import DefaultCookieSettings from '@/models/cookies/settings/default-cookie-settings'
import { ICookieState } from './interfaces/cookie-state'

export default class CookieState implements ICookieState {
  public showCookieBanner = true
  public showOptinModal = false
  public cookieConsent: { [key: string]: boolean } = {}
  public useDefaultCookieBanner = false
  public showPrivacyPolicyDetails = false
  public termsAndServicesAccepted = false
  public defaultCookieSettings: DefaultCookieSettings =
    new DefaultCookieSettings()
}
