























































import DefaultCookieSettings from '@/models/cookies/settings/default-cookie-settings'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CookieOptinModal extends Vue {
  @Prop()
  public settings: DefaultCookieSettings

  private closeModal() {
    this.$emit('closeModal')
  }
}
