import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { ICookieState } from '@/vuex/states/interfaces/cookie-state.d'
import CookieState from '../states/cookie-state'
import CookieController from '@/api/cookie-controller'
import CookieRecord from '@/models/cookies/cookie-record'
import DefaultCookieSettings from '@/models/cookies/settings/default-cookie-settings'

export const cookieState: ICookieState = new CookieState()

export const getters: GetterTree<ICookieState, any> = {
  cookieConsent: (state) => state.cookieConsent,
  showCookieBanner: (state) => state.showCookieBanner,
  showOptinModal: (state) => state.showOptinModal,
  useDefaultCookieBanner: (state) => state.useDefaultCookieBanner,
  showPrivacyPolicyDetails: (state) => state.showPrivacyPolicyDetails,
  termsAndServicesAccepted: (state) => state.termsAndServicesAccepted,
  defaultCookieSettings: (state): DefaultCookieSettings =>
    state.defaultCookieSettings,
}

export const mutations: MutationTree<ICookieState> = {
  setCookieConsent: (state, cookieConsent: { [key: string]: boolean }) => {
    state.cookieConsent = JSON.parse(JSON.stringify(cookieConsent))
  },
  setShowCookieBanner: (state, showCookieBanner: boolean) => {
    state.showCookieBanner = showCookieBanner
  },
  setShowOptinModal: (state, showOptinModal: boolean) => {
    state.showOptinModal = showOptinModal
  },
  setUseDefaultCookieBanner: (state, useDefaultCookieBanner: boolean) => {
    state.useDefaultCookieBanner = useDefaultCookieBanner
  },
  setShowPrivacyPolicyDetails: (state, showPrivacyPolicyDetails: boolean) => {
    state.showPrivacyPolicyDetails = showPrivacyPolicyDetails
  },
  setTermsAndServicesAccepted: (state, termsAndServicesAccepted: boolean) => {
    state.termsAndServicesAccepted = termsAndServicesAccepted
  },
  setDefaultCookieSettings: (
    state: ICookieState,
    settings: DefaultCookieSettings
  ) => {
    state.defaultCookieSettings = settings
  },
}

export const actions: ActionTree<ICookieState, any> = {
  async submitCookieConsent(
    { commit },
    cookieConsent: { [key: string]: boolean }
  ) {
    commit('setCookieConsent', cookieConsent)
  },
  async submitShowCookieBanner({ commit }, showCookieBanner: boolean) {
    commit('setShowCookieBanner', showCookieBanner)
  },
  async submitShowOptinModal({ commit }, showOptinModal: boolean) {
    commit('setShowOptinModal', showOptinModal)
  },
  async retrieveCookieRecord({ commit, rootState }) {
    const { cookieId } =
      rootState.clientModule.clientTemplate.templateCookieRecord
    await CookieController.RetrieveCookieRecord(cookieId).then(
      (cookieRecord: CookieRecord | null) => {
        const useDefaultCookieBanner =
          (cookieRecord && cookieRecord.isDefault) || !cookieRecord
        commit('setUseDefaultCookieBanner', useDefaultCookieBanner)
      }
    )
  },
  showPrivacyPolicyDetails({ commit }, showPrivacyPolicyDetails: boolean) {
    commit('setShowPrivacyPolicyDetails', showPrivacyPolicyDetails)
  },
  acceptTermsAndServices({ commit }, acceptTermsAndServices: boolean) {
    commit('setTermsAndServicesAccepted', acceptTermsAndServices)
  },
  setDefaultCookieSettings({ commit }, settings: DefaultCookieSettings) {
    commit('setDefaultCookieSettings', settings)
  },
}

export const cookieModule: Module<ICookieState, any> = {
  state: cookieState,
  actions,
  mutations,
  getters,
  namespaced: true,
}
