var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":"Customer availability"}},[_c('v-container',{staticClass:"mt-3 pa-4",style:({ backgroundColor: _vm.clientTemplate.secondaryColor })},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"role":"region","aria-label":"First slot availability"}},[_c('v-col',{staticClass:"mb-2 pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('TimeSlotPicker',{attrs:{"header-text":_vm.firstPickedDate,"date-selection":true,"filter-by-date":true,"filter-slot-by-time":_vm.isTodayDate(_vm.firstPickedDate),"slot-type":"Today","model":_vm.customerAvailability,"slot-items":_vm.today,"picked-dates":_vm.pickedDates,"can-delete":false},on:{"update:slotItems":function($event){_vm.today=$event},"update:slot-items":function($event){_vm.today=$event},"updateSlotItems":_vm.onSlotSelectionChange},model:{value:(_vm.today),callback:function ($$v) {_vm.today=$$v},expression:"today"}}),(failedRules.required)?_c('div',{staticClass:"error--text my-2 text-h6"},[_vm._v(" This field is required ")]):_vm._e()]}}])})],1),(
          !_vm.hasSecondSlotAvailabilities &&
          _vm.tomorrow.length === 0 &&
          _vm.dayAfterTomorrow.length === 0 &&
          _vm.today.length > 0
        )?_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary--text grey lighten-3 ma-0 elevation-0",attrs:{"icon":"","text":"","color":"primary","label":"Add second slot availability","aria-label":"Add second slot availability"},on:{"click":_vm.showSecondSlotAvailabilities}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,40937836)},[_c('span',[_vm._v("More Availability Slots")])])],1):_vm._e()],1),_c('section',{attrs:{"role":"region","aria-label":"Second slot availability"}},[(
          _vm.hasSecondSlotAvailabilities ||
          _vm.tomorrow.length > 0 ||
          _vm.dayAfterTomorrow.length > 0
        )?_c('v-col',{staticClass:"mb-2 pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var failedRules = ref.failedRules;
return [_c('TimeSlotPicker',{attrs:{"header-text":_vm.secondPickedDate,"date-selection":true,"filter-by-date":true,"filter-slot-by-time":_vm.isTodayDate(_vm.secondPickedDate),"slot-type":"Tomorrow","model":_vm.customerAvailability,"slot-items":_vm.tomorrow,"picked-dates":_vm.pickedDates,"can-delete":true},on:{"update:slotItems":function($event){_vm.tomorrow=$event},"update:slot-items":function($event){_vm.tomorrow=$event},"updateSlotItems":_vm.onSlotSelectionChange,"delete":_vm.onDeleteTimeSlotPicker},model:{value:(_vm.tomorrow),callback:function ($$v) {_vm.tomorrow=$$v},expression:"tomorrow"}}),(failedRules.required)?_c('div',{staticClass:"error--text my-2 text-h6"},[_vm._v(" This field is required ")]):_vm._e()]}}],null,false,1668188761)})],1):_vm._e(),(
          !_vm.hasThirdSlotAvailabilities &&
          _vm.dayAfterTomorrow.length === 0 &&
          _vm.tomorrow.length > 0
        )?_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary--text grey lighten-3 ma-0 elevation-0",attrs:{"icon":"","text":"","color":"primary","label":"Add third slot availability","aria-label":"Add third slot availability"},on:{"click":_vm.showThirdSlotAvailabilities}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2012945535)},[_c('span',[_vm._v("More Availability Slots")])])],1):_vm._e()],1),_c('section',{attrs:{"role":"region","aria-label":"Third slot availability"}},[(_vm.hasThirdSlotAvailabilities || _vm.dayAfterTomorrow.length > 0)?_c('v-col',{staticClass:"mb-2 pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var failedRules = ref.failedRules;
return [_c('TimeSlotPicker',{attrs:{"header-text":_vm.thirdPickedDate,"date-selection":true,"filter-by-date":true,"filter-slot-by-time":_vm.isTodayDate(_vm.thirdPickedDate),"slot-type":"DayAfterTomorrow","model":_vm.customerAvailability,"slot-items":_vm.dayAfterTomorrow,"picked-dates":_vm.pickedDates,"can-delete":true},on:{"update:slotItems":function($event){_vm.dayAfterTomorrow=$event},"update:slot-items":function($event){_vm.dayAfterTomorrow=$event},"updateSlotItems":_vm.onSlotSelectionChange,"delete":_vm.onDeleteTimeSlotPicker},model:{value:(_vm.dayAfterTomorrow),callback:function ($$v) {_vm.dayAfterTomorrow=$$v},expression:"dayAfterTomorrow"}}),(failedRules.required)?_c('div',{staticClass:"error--text my-2 text-h6"},[_vm._v(" This field is required ")]):_vm._e()]}}],null,false,3179143745)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }