

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Levels } from '@/common/enums'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { ValidationProvider } from 'vee-validate'

interface IHealthAndSafetyLevel {
  id: string
  level: string
}

@Component({
  components: { ValidationProvider },
})
export default class VulnerabilityQA extends Vue {
  public localQA: VulnerabilityQAModel | null = null

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get questionAnswer() {
    return this.insurerPortal.vulnerabilityQA
  }

  @Watch('questionAnswer', { immediate: true })
  onChangeQuestionAnswer(value: VulnerabilityQAModel | null) {
    if (value && !this.localQA) {
      this.localQA = { ...value }
    }
  }

  @Watch('localQA', { deep: true })
  private onLocalQAChange(value: VulnerabilityQAModel | null) {
    this.$store.dispatch('insurerPortalModule/setVulnerabilityQA', value)
  }

  public onSelectionChange(): void {
    if (!this.localQA) {
      return
    }

    if (
      this.localQA.isHealthAffected &&
      (!this.localQA.level || this.localQA.level == Levels[Levels.None])
    ) {
      this.localQA.level = Levels[Levels.Low]
    }

    if (!this.localQA.isHealthAffected) {
      this.localQA.level = null
      this.localQA.answerDetail = null
    }
  }

  // bind dropdown for level from enum
  public get getLevel(): IHealthAndSafetyLevel[] {
    return Object.keys(Levels)
      .filter(Number)
      .map((element: any) => ({
        id: Levels[element],
        level: Levels[element],
      }))
  }

  public getSelectedItem(level: string) {
    return this.localQA && this.localQA.level
      ? level === this.localQA.level
      : false
  }
}
