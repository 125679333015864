





































import { Component, Vue, Prop } from 'vue-property-decorator'
import ClientTemplate from '@/models/client/client-template'
import ImageBase from '@/models/image/image-base'
@Component
export default class ImageDisplay extends Vue {
  @Prop({ default: '' })
  private regionLabel: string

  @Prop({ default: '' })
  private imageHeader: string

  @Prop({ default: '' })
  private removeImageLabel: string

  @Prop({ default: () => [] })
  private images: ImageBase

  @Prop({ default: true })
  private allowDelete: boolean

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private removeImageLabelWithName(img: ImageBase) {
    return `${this.removeImageLabel} - ${img.fileName}`
  }

  private removeImage(img: ImageBase) {
    this.$emit('removeImage', img)
  }
}
