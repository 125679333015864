import Axios, { AxiosResponse } from 'axios'
import { getBaseApiUrl, handleUnexpectedStatusResponse } from './default'
import VerifyPortalAccessUserModel from '@/models/VerifyPortalAccessUserModel'
import VerifyClaimTrackingUserModel from '@/models/VerifyClaimTrackingUserModel'
import ValidateVerificationCodeModel from '@/models/ValidateVerificationCodeModel'
import ValidateClaimTrackingVerificationCodeModel from '@/models/ValidateClaimTrackingVerificationCodeModel'
import ResponseOfValidateVerificationCodeModel from '@/models/ResponseOfValidateVerificationCodeModel'
import RequestVerificationCodeModel from '@/models/RequestVerificationCodeModel'
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import VerifyCustomerResponse from '@/models/verify-customer-response'
import EnvironmentSecrets from '@/models/generic/environment-secrets'

export default class SessionController {
  public static async ValidateVerificationCode(
    validateVerificationCodeModel: ValidateVerificationCodeModel
  ): Promise<ResponseOfValidateVerificationCodeModel | null> {
    const res: AxiosResponse = await Axios.post(
      getBaseApiUrl() + 'Session/ValidateVerificationCode',
      validateVerificationCodeModel
    )
    if (res.status >= 200 && res.status <= 300) {
      const result = res.data as ResponseOfValidateVerificationCodeModel
      return result || null
    } else {
      handleUnexpectedStatusResponse(res)
      return null
    }
  }

  public static async ValidateClaimTrackingVerificationCode(
    validateVerificationCodeModel: ValidateClaimTrackingVerificationCodeModel
  ): Promise<ResponseOfValidateVerificationCodeModel | null> {
    const res: AxiosResponse = await Axios.post(
      getBaseApiUrl() + 'Session/ValidateClaimTrackingVerificationCode',
      validateVerificationCodeModel
    )
    if (res.status >= 200 && res.status <= 300) {
      const result = res.data as ResponseOfValidateVerificationCodeModel
      return result || null
    } else {
      handleUnexpectedStatusResponse(res)
      return null
    }
  }

  public static async ReSendVerificationCode(
    requestVerificationCodeModel: RequestVerificationCodeModel
  ): Promise<boolean | null> {
    const res: AxiosResponse = await Axios.post(
      getBaseApiUrl() + 'Session/ReSendVerificationCode',
      requestVerificationCodeModel
    )
    if (res.status === 202) {
      return true
    } else {
      handleUnexpectedStatusResponse(res)
      return false
    }
  }

  public static async verifyCustomer(
    verifyCustomerModel: VerifyPortalAccessUserModel
  ): Promise<VerifyCustomerResponse | null> {
    const response: AxiosResponse = await Axios.post(
      `${getBaseApiUrl()}Session/VerifyCustomer`,
      verifyCustomerModel
    )
    return response.status >= 200 && response.status <= 300
      ? JSON.parse(response.data)
      : null
  }

  public static async verifyCustomerForClaimTracking(
    verifyCustomerModel: VerifyClaimTrackingUserModel
  ): Promise<VerifyCustomerResponse | null> {
    const response: AxiosResponse = await Axios.post(
      `${getBaseApiUrl()}Session/VerifyCustomerForClaimTracking`,
      verifyCustomerModel
    )
    return response.status >= 200 && response.status <= 300
      ? JSON.parse(response.data)
      : null
  }

  public static async verifyRecaptcha(
    recaptchaToken: string
  ): Promise<RecaptchaResponse | null> {
    const response: AxiosResponse = await Axios.post(
      `${getBaseApiUrl()}Session/VerifyRecaptcha?captchaToken=${encodeURIComponent(
        recaptchaToken
      )}`
    )
    return response.status >= 200 && response.status <= 300
      ? JSON.parse(response.data)
      : null
  }

  public static async getEnvironmentSecrets(): Promise<EnvironmentSecrets | null> {
    const response: AxiosResponse = await Axios.get(
      `${getBaseApiUrl()}session/environment/settings`
    )
    return response.status === 200 ? JSON.parse(response.data) : null
  }
}
