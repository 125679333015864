


























































































































































import { Component, Vue, Watch, Inject } from 'vue-property-decorator'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import VAutocompleteCustom from './custom-vuetify/VAutocompleteCustom'

@Component({
  components: {
    ValidationProvider,
    VAutocompleteCustom,
  },
})
export default class CustomerAddress extends Vue {
  @Inject('formWrapperGetter') formWrapperGetter: () => InstanceType<
    typeof ValidationObserver
  >
  $refs!: {
    [k: string]: any
  }

  private isShowDetailAddress = false
  private searchAddressRunning = false
  private searchResult: any = []
  private searchAddress = ''
  private waitForMoreInputTimeoutHandle: number | null = null
  private addressSearchModel: any = ''

  private get formWrapper() {
    return this.formWrapperGetter()
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get addressLine1() {
    return this.insurerPortal.addressLine1
  }
  private set addressLine1(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine1', value)
  }

  private get addressLine2() {
    return this.insurerPortal.addressLine2
  }
  private set addressLine2(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine2', value)
  }

  private get addressLine3() {
    return this.insurerPortal.addressLine3
  }
  private set addressLine3(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine3', value)
  }

  private get addressLine4() {
    return this.insurerPortal.addressLine4
  }
  private set addressLine4(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine4', value)
  }

  private get postcode() {
    return this.insurerPortal.postcode
  }
  private set postcode(value: string) {
    this.$store.dispatch('insurerPortalModule/setPostcode', value)
  }

  @Watch('getTrackingId', { immediate: true })
  private async getLoqateApiKeyForAddress() {
    if (this.getTrackingId && !this.getLoqateApiKey && !this.appInEditMode) {
      await this.$store.dispatch('insurerPortalModule/getLoqateApiKey')
    }
  }

  private mounted() {
    if (this.appInEditMode) {
      this.searchAddress = 'Test'
      this.addressLine1 = '392, St. Georges Crescent'
      this.addressLine2 = 'Old Marske'
      this.addressLine3 = 'Redcar'
      this.addressLine4 = 'Cleveland'
      this.postcode = 'TS11 8BU'
      this.isShowDetailAddress = true
      this.searchResult = []
    }
  }

  private get getLoqateApiKey(): string {
    return this.$store.getters['generalModule/loqateApiKey']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  @Watch('addressSearchModel')
  private async addressSearchModelChange(item: any) {
    if (item && item.Type !== 'Address') {
      this.addressVerification(item.Text, item.Id, true)
      return
    }
    if (item && this.getLoqateApiKey) {
      const hasData = await this.$store.dispatch(
        'insurerPortalModule/retrieveAddressLoqateApi',
        {
          id: item.Id,
          apiKey: this.getLoqateApiKey,
        }
      )

      if (hasData) {
        this.isShowDetailAddress = true
        this.$refs.searchAddressField.blur()
      }
    }
  }

  @Watch('searchAddress')
  private addressVerification(text: string, id: string, isReSearch: boolean) {
    const item = this.searchResult.find(
      (i: any) => i.Description === this.searchAddress
    )
    if (!isReSearch && (!this.searchAddress || item)) {
      return
    }
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    this.waitForMoreInputTimeoutHandle = window.setTimeout(async () => {
      if (this.getLoqateApiKey) {
        this.searchAddressRunning = true
        const reSearchId = isReSearch ? id : ''

        const { hasData, searchResult } = await this.$store.dispatch(
          'insurerPortalModule/findAddressFromLoqateApi',
          {
            text: text,
            apiKey: this.getLoqateApiKey,
            id: reSearchId,
          }
        )
        this.searchAddressRunning = false

        if (!hasData) return (this.searchResult = [])

        this.searchResult = searchResult
        this.isShowDetailAddress = false

        if (isReSearch) {
          this.$refs.searchAddressField.focus()
          this.$refs.searchAddressField.isMenuActive = true
        }
      }
    }, 300)
  }

  private toggleActiveAddressSearch(isActive: boolean) {
    this.isShowDetailAddress = !isActive
    this.addressSearchModel = ''
    this.clearAddressFields()
    this.formWrapper.reset()
    setTimeout(() => {
      let inputElement: any
      if (isActive) {
        inputElement = this.$refs.searchAddressField
      } else {
        inputElement = this.$refs.addressLine1
      }
      if (inputElement) {
        inputElement.focus()
      }
    }, 0)
  }

  private clearAddressFields() {
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postcode = ''

    this.searchResult = []
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
