





























































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Environment from '@/common/environment'
import EmergencyQA from '@/models/EmergencyQA'
import { AnswerType, OperatorType } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import HealthSafetyQA from '@/components/HealthSafetyQA.vue'
import VulnerabilityQA from '@/components/VulnerabilityQA.vue'
import Shared from '@/common/shared'
import ClientTemplate from '@/models/client/client-template'
import { ValidationProvider } from 'vee-validate'
import ReturnEmergencyQuestionDataModel from '@/models/ReturnEmergencyQuestionDataModel'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import SearchEmergencyQuestionModel from '@/models/SearchEmergencyQuestionModel'
import VSelectCustom from './custom-vuetify/VSelectCustom'
@Component({
  components: {
    HealthSafetyQA,
    VulnerabilityQA,
    ValidationProvider,
    VSelectCustom,
  },
})
export default class EmergencyQuestionGenerator extends Vue {
  private localTimer: Date = new Date()
  public answerType = AnswerType
  public defaultSelectionOption = 'Select an option'

  public isLoading = false

  public noQuestions(emergency: AddEmergency): boolean {
    return (
      !this.isLoading &&
      this.getEmergencyQAs(emergency).length === 0 &&
      !this.getHealthAndSafetyQAs(emergency)
    )
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get acceptedEmergencies(): number[] {
    return this.$store.getters['insurerPortalModule/acceptedEmergencies']
  }

  public get emergenciesQAs() {
    return this.insurerPortal.emergenciesQAs ?? []
  }

  private set emergenciesQAs(payload: EmergencyQA[]) {
    this.$store.dispatch('insurerPortalModule/setEmergenciesQAs', payload)
  }

  private get emergencies() {
    return this.insurerPortal.emergencies
  }

  private set emergencies(payload: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', payload)
  }

  private get healthAndSafetyQAs() {
    return this.insurerPortal.healthAndSafetyQAs ?? []
  }

  private set healthAndSafetyQAs(payload: HealthAndSafetyQAModel[]) {
    this.$store.dispatch('insurerPortalModule/setHealthAndSafetyQAs', payload)
  }

  private get vulnerabilityQA() {
    return this.insurerPortal.vulnerabilityQA
  }

  private set vulnerabilityQA(payload: VulnerabilityQAModel | null) {
    this.$store.dispatch('insurerPortalModule/setVulnerabilityQA', payload)
  }

  private get accessNoteQA() {
    return this.insurerPortal.accessNoteQA ?? null
  }

  private set accessNoteQA(payload: AccessNoteModel | null) {
    this.$store.dispatch('insurerPortalModule/setAccessNoteQA', payload)
  }

  private get policyScheduleId() {
    return this.insurerPortal.policyScheduleId
  }
  private set policyScheduleId(payload: number) {
    this.$store.dispatch('insurerPortalModule/setPolicyScheduleId', payload)
  }

  private get policyName() {
    return this.insurerPortal.policyName
  }
  private set policyName(payload: string) {
    this.$store.dispatch('insurerPortalModule/setPolicyName', payload)
  }

  private get policyNumber() {
    return this.insurerPortal.policyNumber
  }

  private set policyNumber(payload: string) {
    this.$store.dispatch('insurerPortalModule/setPolicyNumber', payload)
  }

  private get isPolicyCovered() {
    return this.insurerPortal.isPolicyCovered as boolean
  }

  private set isPolicyCovered(payload: boolean) {
    this.$store.dispatch('insurerPortalModule/setIsPolicyCovered', payload)
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }
  private get trackingIdParam(): string {
    return this.$route.params.trackingId
  }

  @Watch('currentStep', { immediate: true })
  private async getData() {
    if (this.currentStep === 5) {
      this.isLoading = true
      if (this.appInEditMode) {
        // get dummy question list
        this.getDummyQuestionsForClientTemplate()
      } else if (!this.trackingIdParam) {
        // Load real data from API when not in question mode.
        // When in question mode, use data in vuex insurePortalModule instead
        await this.getPolicyQuestions()
      }

      this.isLoading = false
    }
  }

  @Watch('emergenciesQAs', { deep: true })
  private onChangeEmergenciesQAs(value: EmergencyQA[]) {
    this.emergenciesQAs = value
  }

  @Watch('accessNoteQA', { deep: true })
  private onChangeAccessNoteQA(value: AccessNoteModel) {
    this.accessNoteQA = value
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // get policy and emergencies questions
  // policy decide based on firstname, lastname, postcode, policy schedules, fallback starred policy, selected emergency and details
  private async getPolicyQuestions() {
    this.scrollToTop()
    // set properties for search emergency question
    const searchEmergencyQuestion = new SearchEmergencyQuestionModel()
    if (this.insurerPortal.emergencies) {
      this.insurerPortal.emergencies.forEach((element: AddEmergency) => {
        if (element && element.emergencyTypeId) {
          searchEmergencyQuestion.emergencyTypeIds.push(element.emergencyTypeId)
        }
        if (element && element.emergencyDetailId) {
          searchEmergencyQuestion.emergencyDetailIds.push(
            element.emergencyDetailId
          )
        }
      })
    } else {
      searchEmergencyQuestion.emergencyTypeIds = []
      searchEmergencyQuestion.emergencyDetailIds = []
    }

    if (!searchEmergencyQuestion.policyIds) {
      searchEmergencyQuestion.policyIds = []
    }

    const items = this.clientTemplate.policySchedules
    if (!items) return

    searchEmergencyQuestion.policyIds = items.filter((x) => x)
    searchEmergencyQuestion.fallBackStarPolicyId =
      this.clientTemplate.fallBackStarPolicy
    searchEmergencyQuestion.firstName = this.insurerPortal.clientForename
    searchEmergencyQuestion.lastName = this.insurerPortal.clientLastName
    searchEmergencyQuestion.postcode = this.insurerPortal.postcode

    const hasData: boolean = await this.$store.dispatch(
      'insurerPortalModule/getPolicyQuestions',
      searchEmergencyQuestion
    )

    if (hasData) {
      this.handleLinkClicksInGA('User Address Located', 'NEXT')
    }
  }

  getAriaLabel(prefix: string, emergenciesQA: any, postfix?: string) {
    if (!emergenciesQA) return ''

    return `${prefix} ${emergenciesQA.questionText} ${
      emergenciesQA.unit ? '(' + emergenciesQA.unit + ')' : ''
    } ${postfix ? postfix : ''}`
  }

  private handleLinkClicksInGA(
    categoryName: string,
    actionName: string,
    time: Date | null = null
  ) {
    if (this.appInEditMode) {
      return
    }
    Shared.sendEventsInGoogleAnalytics(
      categoryName,
      actionName,
      time ? time : this.localTimer
    )
    // reset timer for second button click
    this.localTimer = new Date()
  }

  private getDummyQuestionsForClientTemplate() {
    this.scrollToTop()
    const healthAndSafetyQAs: any = [
      {
        answerDetail: null,
        createdAt: '2019-06-07T09:19:43.7587364Z',
        forEmergencyDetailId: 4,
        forEmergencyTypeId: 1,
        isHealthAffected: null,
        level: null,
        questionText:
          'Does the emergency you’re experiencing pose any health and safety concerns?',
        type: 'HealthAndSafetyQA',
      },
    ]
    const vulnerabilityQA: any = {
      answerDetail: null,
      isHealthAffected: null,
      level: null,
      questionText:
        'Is there anything I need to make my colleagues or engineers aware of about anyone at the property who may need additional support when we are dealing with your Home Emergency claim?',
      type: 'VulnerabilityQA',
    }
    const accessNoteQA: any = {
      answer: null,
      createdAt: '2019-06-07T09:19:43.7587364Z',
      questionText: 'Are there any property access considerations?',
      type: 'AccessNote',
    }
    const res: ReturnEmergencyQuestionDataModel = {
      questions: [],
      policyId: 1,
      policyName: 'Accord Building Society',
      policyNumber: '',
      isPolicyCovered: false,
      healthAndSafetyQAs: [],
      vulnerabilityQA: null,
      accessNoteQA: null,
    }
    const questions: any = [
      {
        forEmergencyTypeId: 21,
        forEmergencyDetailId: null,
        policyQuestionId: 277,
        questionId: 49,
        questionText: 'Is this causing internal damage to the property?',
        orderBy: 1,
        answer: null,
        forCommentOperatorId: 1,
        forCommentComparisonValue: 'Yes',
        commentLabel: 'Please provide more details',
        comment: null,
        answerType: 1,
        additionalValues: null,
        unit: null,
        mandatory: true,
      },
      {
        forEmergencyTypeId: 21,
        forEmergencyDetailId: null,
        policyQuestionId: 281,
        questionId: 57,
        questionText: 'How many people reside in the property?',
        orderBy: 6,
        answer: null,
        forCommentOperatorId: null,
        forCommentComparisonValue: null,
        commentLabel: null,
        comment: null,
        answerType: 3,
        additionalValues: null,
        unit: null,
        mandatory: true,
      },
      {
        forEmergencyTypeId: 21,
        forEmergencyDetailId: null,
        policyQuestionId: 476,
        questionId: 6,
        questionText: 'Is the boiler easily accessible?',
        orderBy: 5,
        answer: null,
        forCommentOperatorId: 1,
        forCommentComparisonValue: 'No',
        commentLabel: 'Please give details here',
        comment: null,
        answerType: 2,
        additionalValues: 'Yes\nNo',
        unit: null,
        mandatory: true,
      },
    ]
    const emergencies: any = [
      {
        emergencyTypeId: 21,
        emergencyTypeDesc: 'Buildings or Contents',
        emergencyDetailId: 40,
        emergencyDetailDesc: 'General Fire Damage',
        emergencyDetail: {
          id: 40,
          typeId: 21,
          description: 'General Fire Damage',
          tradeId: 13,
        },
      },
    ]
    res.questions = questions
    this.vulnerabilityQA = vulnerabilityQA
    this.accessNoteQA = accessNoteQA
    this.healthAndSafetyQAs = healthAndSafetyQAs
    this.emergenciesQAs = res.questions
    this.emergencies = emergencies

    this.policyScheduleId = res.policyId
    this.policyName = res.policyName
    this.policyNumber = res.policyNumber
    this.isPolicyCovered = res.isPolicyCovered as boolean
  }

  public dropdownOptions(
    answerType: AnswerType,
    additionalValues: string | null
  ): string[] {
    if (answerType === AnswerType.Dropdown && additionalValues) {
      const items = additionalValues.split('\n')
      if (items && items.length > 0) {
        for (let i = 0, l = items.length; i < l; i++) {
          items[i] = items[i].trim()
        }
        return items
      }
    }
    return []
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public getEmergencyAgnosticQAs() {
    const emergencyQAs = this.emergenciesQAs.filter(
      (e) => e.forEmergencyTypeId === null && e.forEmergencyDetailId === null
    )

    this.disableQuestionsForAcceptedEmergencies(emergencyQAs)

    return emergencyQAs
  }

  public getEmergencyQAs(emergency: AddEmergency) {
    const emergencyQAs = this.emergenciesQAs.filter(
      (e) =>
        e.forEmergencyTypeId === emergency.emergencyTypeId ||
        e.forEmergencyDetailId === emergency.emergencyDetailId
    )

    this.disableQuestionsForAcceptedEmergencies(emergencyQAs)

    return emergencyQAs
  }

  private disableQuestionsForAcceptedEmergencies(emergencyQAs: EmergencyQA[]) {
    // question mode - if emergency is accepted, disable answers
    if (this.acceptedEmergencies.length > 0) {
      emergencyQAs.forEach((question) => {
        const isAcceptedEmergency = this.acceptedEmergencies.find(
          (i) => i === question.forEmergencyTypeId
        )
        question.disabled = isAcceptedEmergency ? true : false
      })
    }
    return emergencyQAs
  }

  private getHealthAndSafetyQAs(emergency: AddEmergency) {
    const healthAndSafetyQAs = this.healthAndSafetyQAs.find(
      (e) =>
        e.forEmergencyTypeId === emergency.emergencyTypeId ||
        e.forEmergencyDetailId === emergency.emergencyDetailId
    )
    // question mode - if emergency is accepted, disable answers
    if (healthAndSafetyQAs) {
      ;(healthAndSafetyQAs as any).disabled =
        this.acceptedEmergencies.length > 0
          ? this.acceptedEmergencies.findIndex(
              (i) => i === healthAndSafetyQAs.forEmergencyTypeId
            ) !== -1
            ? true
            : false
          : false
    }
    return healthAndSafetyQAs
  }

  public get getVulnerabilityQuestion(): VulnerabilityQAModel | null {
    return this.vulnerabilityQA
  }

  public get getAccessNoteQuestion(): AccessNoteModel | null {
    return this.accessNoteQA
  }

  public get getEmergencies(): AddEmergency[] {
    return this.emergencies
  }

  public validationForAdditionalInfoStatus(emergencyQA: EmergencyQA): boolean {
    // validate based on comparison method and mandatory flag
    switch (emergencyQA.answerType) {
      case AnswerType.Text:
        return this.validateDropDownAndTextBox(emergencyQA)
      case AnswerType.Dropdown:
        return this.validateDropDownAndTextBox(emergencyQA)
      case AnswerType.Number:
        return this.validateNumber(emergencyQA)
      case AnswerType.Checkbox:
        return this.validateBoolean(emergencyQA)
      default:
        // unexpected type, dont know how to validate
        return false
    }
  }

  private validateDropDownAndTextBox(emergencyQA: EmergencyQA): boolean {
    let comparingValue = ''
    if (emergencyQA.forCommentComparisonValue) {
      comparingValue = emergencyQA.forCommentComparisonValue.trim()
    }
    let res: boolean
    switch (emergencyQA.forCommentOperatorId) {
      case null:
        res = true
        break
      case OperatorType.EqualTo:
        res = emergencyQA.answer
          ? emergencyQA.answer.trim() === comparingValue
          : false
        break
      case OperatorType.NotEqualTo:
        res = emergencyQA.answer
          ? emergencyQA.answer.trim() !== comparingValue
          : false
        break
      case OperatorType.In: {
        const items = comparingValue ? comparingValue.split(',') : null
        let validResult = false
        if (items) {
          items.forEach((item) => {
            if (emergencyQA.answer) {
              if (emergencyQA.answer.trim() === item.trim()) {
                validResult = true
              }
            }
          })
        }
        res = validResult
        break
      }
      default:
        // bad choice for dropdown
        return false
    }
    if (res) {
      return true
    } else {
      return false
    }
  }

  private validateNumber(emergencyQA: EmergencyQA): boolean {
    let num: number
    let cNum = 0
    const cNums: number[] = []
    num = parseFloat(emergencyQA.answer)
    if (
      emergencyQA.forCommentOperatorId === OperatorType.In &&
      emergencyQA.forCommentComparisonValue
    ) {
      emergencyQA.forCommentComparisonValue.split(',').forEach((ans) => {
        cNums.push(parseFloat(ans.trim()))
      })
    } else {
      cNum = emergencyQA.forCommentComparisonValue
        ? parseFloat(emergencyQA.forCommentComparisonValue)
        : 0
    }
    let res: boolean
    switch (emergencyQA.forCommentOperatorId) {
      case null:
        res = true
        break
      case OperatorType.GreaterThan:
        res = num > cNum
        break
      case OperatorType.LessThan:
        res = num < cNum
        break
      case OperatorType.GreaterThanOrEqualTo:
        res = num >= cNum
        break
      case OperatorType.LessThanOrEqualTo:
        res = num <= cNum
        break
      case OperatorType.EqualTo:
        res = num === cNum
        break
      case OperatorType.NotEqualTo:
        res = num !== cNum
        break
      case OperatorType.In:
        res = cNums.indexOf(num) > -1
        break
      default:
        // unexpected conparision type for number
        return false
    }
    if (res) {
      return true
    } else {
      return false
    }
  }

  private validateBoolean(emergencyQA: EmergencyQA) {
    // note: comparision value should be either 'true' or 'false';
    let res: boolean
    switch (emergencyQA.forCommentOperatorId) {
      case null:
        res = true
        break
      case OperatorType.EqualTo:
        res = emergencyQA.answer === emergencyQA.forCommentComparisonValue
        break
      case OperatorType.NotEqualTo:
        res = emergencyQA.answer !== emergencyQA.forCommentComparisonValue
        break
      default:
        return false
    }
    if (res) {
      return true
    } else {
      return false
    }
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  public getSelectedItem(answer: string, selectedAnswer: string) {
    if (selectedAnswer) {
      return selectedAnswer === answer
    }
    return false
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
