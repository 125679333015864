import moment from 'moment'
import { ValidationRule } from 'vee-validate/dist/types/types'

export const validDateFormat: ValidationRule = {
  validate(value, args) {
    const { format } = args as { format: string }
    return moment(value, format, true).isValid()
  },
  params: ['format'],
  message: (fileName) => {
    return `The ${fileName} date is invalid.`
  },
}
export const emergencyValidDateTime: ValidationRule = {
  validate(value, args) {
    const { format } = args as { format: string }
    return moment(value, format).isSameOrBefore(moment())
  },
  params: ['format'],
  message: (fileName) => {
    return `The ${fileName} date is invalid.`
  },
}

export const validDateInTimeSlotPicker: ValidationRule = {
  validate(value, args) {
    const { format, isSelectedDateIsSameOrAfterMinDate, isDateNotSelected } =
      args as {
        format: string
        isSelectedDateIsSameOrAfterMinDate: boolean
        isDateNotSelected: boolean
      }

    return (
      moment(value, format, true).isValid() &&
      isSelectedDateIsSameOrAfterMinDate &&
      isDateNotSelected
    )
  },
  params: ['format', 'isSelectedDateIsSameOrAfterMinDate', 'isDateNotSelected'],
  message: (fileName) => {
    return `The ${fileName} date is invalid.`
  },
}
