























import { Component, Vue } from 'vue-property-decorator'
import InternalCookiePolicy from '@/components/cookie/InternalCookiePolicy.vue'
import ClientTemplate from '@/models/client/client-template'
import Shared from '@/common/shared'

const oneTrustContent = [
  {
    placeholder: '{{COOKIETEXT}}',
    HTML: '<div id="ot-sdk-cookie-policy"></div>',
  },
  {
    placeholder: '{{COOKIEBUTTON}}',
    HTML: '<div id="ot-sdk-btn" class="ot-preference-btn ot-sdk-show-settings" tabindex="0"></div>',
  },
]

@Component({
  components: {
    InternalCookiePolicy,
  },
})
export default class CookieNotice extends Vue {
  private sanitizeHTML = Shared.sanitizeHTML

  private get useDefaultCookieBanner(): boolean {
    return this.$store.getters['cookieModule/useDefaultCookieBanner']
  }

  public get clientTemplate(): ClientTemplate {
    const clientTemplate = this.$store.getters['clientModule/clientTemplate']

    if (!this.useDefaultCookieBanner) {
      oneTrustContent.forEach((content) => {
        clientTemplate.cookieNoticeText =
          clientTemplate.cookieNoticeText.replace(
            content.placeholder,
            content.HTML
          )
      })
    }

    return clientTemplate
  }
}
