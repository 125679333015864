import type { Store } from 'vuex'

export default class Environment {
  public apiBaseUrl: string = process.env.VUE_APP_API_BASE_URL ?? ''
  public locateFindApi: string = process.env.VUE_APP_LOCATE_FIND_API ?? ''
  public locateRetrieveApi: string =
    process.env.VUE_APP_LOCATE_RETRIEVE_API ?? ''
  public logoSize: number = Number(process.env.VUE_APP_LOGO_SIZE) ?? 0
  public dateTimeFormat: string = process.env.VUE_APP_DATE_TIME_FORMAT ?? ''
  public dateFormat: string = process.env.VUE_APP_DATE_FORMAT ?? ''
  public policyHolderAppBaseLogoUrl: string =
    process.env.VUE_APP_POLICY_HOLDER_APP_BASE_LOGO_URL ?? ''
  public editHostUrl: string = process.env.VUE_APP_EDIT_HOST_URL ?? ''
  public environmentMode: string = process.env.VUE_APP_ENVIRONMENT_MODE ?? ''

  // Pulled from Client Management API
  public reCaptchaClientKey = ''
  public isReCaptchaEnabled = true
  public googleAnalyticsKey: ''
  public googleMapsApiKey = ''
}

export enum EnvironmentMode {
  NotSet = 0,
  Development = 1,
  QA = 2,
  UAT = 3,
  Production = 4,
}

export const getEnvMode = (store: Store<any>): EnvironmentMode => {
  const environment: Environment = store.getters['generalModule/environment']
  return EnvironmentMode[
    environment.environmentMode as keyof typeof EnvironmentMode
  ]
}
