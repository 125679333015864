
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class EditableHeader extends Vue {
  @Prop() private heading: string
  @Prop() private description: string
  @Prop() private primaryColor: string
  @Prop() private complementaryColor: string
}
