import SnackBar from './snackbar'

class ErrorSnackBar extends SnackBar {
  public backgroundColour = 'red'

  constructor(message: string) {
    super(message)
  }
}

export default ErrorSnackBar
