import Environment from '@/common/environment'
import store from '@/store'
import { AxiosResponse } from 'axios'

function env(): Environment {
  return store.getters['generalModule/environment']
}

export function getBaseApiUrl(): string {
  return `${env().apiBaseUrl}/api/`
}

export function getLoqateApiRetrieveUrl(): string {
  return env().locateRetrieveApi
}

export function getLoqateApiFindUrl(): string {
  return env().locateFindApi
}

export function handleUnexpectedStatusResponse(res: AxiosResponse) {
  switch (res.status) {
    case 401:
      // TODO: show logged out overlay
      break
    case 403:
      // TODO: show forbidden overlay
      break
    case 500:
    default:
    // TODO: show sorry something went wrong overlay
  }
}
