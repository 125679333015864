import JobController from '@/api/jobController'
import UpdateQuestionModeJobDetailModel from '@/models/claim/UpdateQuestionModeJobDetailModel'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { ActionTree } from 'vuex'

const actions: ActionTree<IInsurerPortalState, any> = {
  async getJobLocation() {
    return await JobController.GetJobLocation()
  },
  getJobTrackingDocuments() {
    return JobController.GetJobTrackingDocuments()
  },
  getJobDocument(_, documentId: string) {
    return JobController.GetJobDocument(documentId)
  },
  updateQuestionModeJobDetails(
    _,
    payload: { subDomain: string; model: UpdateQuestionModeJobDetailModel }
  ) {
    const { subDomain, model } = payload
    return JobController.UpdateQuestionModeJobDetails(subDomain, model)
  },
  addJob(
    _,
    payload: { subDomain: string; portalId: string; model: InsurerPortalModel }
  ) {
    const { subDomain, portalId, model } = payload
    return JobController.AddJob(subDomain, portalId, model)
  },
}
export default actions
