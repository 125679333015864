





































import { ImageType } from '@/common/enums'
import ClientTemplate from '@/models/client/client-template'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ImageFileSelection from '@/components/image/ImageFileSelection.vue'
import ImageBase from '@/models/image/image-base'
import ImageDisplay from '@/components/image/ImageDisplay.vue'

@Component({
  components: {
    ImageFileSelection,
    ImageDisplay,
  },
})
export default class ImageUpload extends Vue {
  @Prop({ default: true })
  private allowMultipleUploads: boolean
  @Prop({
    default:
      'Adding photos makes it much more likely that we can solve your emergency on our first visit',
  })
  private headerText: string
  @Prop({ default: ImageType.NotSet })
  private imageType: ImageType

  private showImageSelectionModal = false

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get disableUpload(): boolean {
    return (
      (!this.allowMultipleUploads && this.uploadedImages.length === 1) ||
      (this.allowMultipleUploads && this.uploadedImages.length >= 10)
    )
  }

  private get uploadedImages(): ImageBase[] {
    const uploadedImages: ImageBase[] =
      this.$store.getters['imageModule/uploadedImages']
    return uploadedImages.filter((x) => x.imageType === this.imageType)
  }

  private get failedUploads(): ImageBase[] {
    const failedUploads: ImageBase[] =
      this.$store.getters['imageModule/failedUploaded']
    return failedUploads.filter((x) => x.imageType === this.imageType)
  }

  private async removeUploadedImage(image: ImageBase) {
    this.$store.dispatch('imageModule/submitUploadedImageToRemove', image)
  }

  private get isPreviewMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }
}
