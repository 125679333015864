import AddEmergency from '@/models/AddEmergency'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import EmergencyQA from '@/models/EmergencyQA'
import { ContactPreference } from '@/models/types'
import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { ActionTree } from 'vuex'

const actions: ActionTree<IInsurerPortalState, any> = {
  setVulnerabilityQA({ commit }, vulnerabilityQA: VulnerabilityQAModel) {
    commit('setVulnerabilityQA', vulnerabilityQA)
  },
  setAccessNoteQA({ commit }, accessNoteQA: AccessNoteModel) {
    commit('setAccessNoteQA', accessNoteQA)
  },
  setHealthAndSafetyQAs(
    { commit },
    healthAndSafetyQAs: HealthAndSafetyQAModel[]
  ) {
    commit('setHealthAndSafetyQAs', healthAndSafetyQAs)
  },
  setEmergenciesQAs({ commit }, emergenciesQAs: EmergencyQA[]) {
    commit('setEmergenciesQAs', emergenciesQAs)
  },
  setEmergencies({ commit }, emergencies: AddEmergency[]) {
    commit('setEmergencies', emergencies)
  },
  setPolicyScheduleId({ commit }, policyScheduleId: number) {
    commit('setPolicyScheduleId', policyScheduleId)
  },
  setPolicyName({ commit }, policyName: string) {
    commit('setPolicyName', policyName)
  },
  setPolicyNumber({ commit }, policyNumber: string) {
    commit('setPolicyNumber', policyNumber)
  },
  setIsPolicyCovered({ commit }, isPolicyCovered: boolean) {
    commit('setIsPolicyCovered', isPolicyCovered)
  },
  setContactPreference({ commit }, contactPreference: ContactPreference) {
    commit('setContactPreference', contactPreference)
  },
}
export default actions
