import { ValidationRule } from 'vee-validate/dist/types/types'

export const validFilesLength: ValidationRule = {
  validate(value, { length, addedFilesLength }: any) {
    return value.length <= length && addedFilesLength + value.length <= length
      ? true
      : false
  },
  params: ['length', 'addedFilesLength'],
  message: (fieldName, placeholders) =>
    placeholders.length === 1
      ? 'You can upload only 1 images'
      : 'You can upload a maximum of {length} images',
}
