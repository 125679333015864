var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"role":"region","aria-label":"Phone number"}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"contact-number pa-0",attrs:{"cols":"12"}},[_c('v-select',{class:'selected-country pt-0 ' + _vm.selectClassName,attrs:{"id":_vm.selectClassName,"items":_vm.countries,"append-icon":"","hide-details":"","aria-label":"Code","single-line":"","disabled":_vm.isDisabled},on:{"change":_vm.changedCountryValue},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"v-select__selection",attrs:{"id":'selected-country-code-' + _vm.selectClassName,"aria-haspopup":"listbox","role":"button","aria-expanded":"false","aria-label":'Code' + data.item.cc + data.item.name}},[_vm._v(" "+_vm._s(data.item.cc || 'INT')+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"country-list-item d-flex",attrs:{"role":"text","tabindex":"0","aria-label":_vm.getSelectedItem(item.code)
                ? item.name +
                  (item.cc ? ' (' + item.cc + ')' : '') +
                  'list item selected'
                : item.name + (item.cc ? ' (' + item.cc + ')' : '')}},on),[_c('v-list-item-avatar',[_c('div',{class:'flag flag-' + item.code})]),_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.name + (item.cc ? ' (' + item.cc + ')' : ''))}})],1)],1)]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('ValidationProvider',{attrs:{"name":"Phone Contact","rules":{
          required: !_vm.isDisabled,
          validCountry: { code: _vm.selectedCountry.code },
          possiblePhoneNumber: { code: _vm.selectedCountry.code },
          validPhoneNumber: { code: _vm.selectedCountry.code },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [(_vm.isValidationRequired)?_c('v-text-field',{ref:"txtNumber",staticClass:"call-number-txt pt-0 d-inline-block",attrs:{"error-messages":errors,"single-line":"","label":"Enter Number","aria-required":"true","disabled":_vm.isDisabled},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}):_vm._e()]}}])})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0 hide"},[_c('v-col',{staticClass:"country-list",attrs:{"cols":"12"}},[_c('v-list',_vm._l((_vm.countries),function(item,index){return _c('v-list-item',{key:index,staticClass:"d-flex",attrs:{"tow-line":""}},[_c('v-list-item-icon',[_c('div',{class:'flag flag-' + item.code})]),_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.name + ' (' + item.cc + ')')}})],1),_c('v-divider')],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }