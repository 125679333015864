var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',{staticClass:"portal-template primary",style:({ background: _vm.clientTemplate.primaryColor + '!important' })},[_c('div',{attrs:{"role":"region","aria-label":"Claim tracking header"}},[_c('v-container',{staticClass:"container--fluid pa-0"},[_c('v-toolbar',{staticClass:"white"},[_c('v-toolbar-title',[_c('img',{staticClass:"img-responsive logo-img",attrs:{"src":_vm.clientTemplate.logoURL
                ? _vm.clientTemplate.logoURL
                : 'img/temp-logo.png',"alt":(_vm.clientTemplate.displayName
                ? _vm.clientTemplate.displayName
                : 'Policy') + ' - Logo'}})]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"content-section",attrs:{"role":"region","aria-label":"Claim tracking content"}},[_c('div',{staticClass:"content-container"},[_c('div',{directives:[{name:"bar",rawName:"v-bar",value:({ useScrollbarPseudo: true }),expression:"{ useScrollbarPseudo: true }"}],staticClass:"details-content elevation-1"},[_c('div',{staticClass:"scroll-content"},[(!_vm.isJobCompleted)?_c('div',[_c('v-tabs-items',{model:{value:(_vm.selectedClaimIndex),callback:function ($$v) {_vm.selectedClaimIndex=$$v},expression:"selectedClaimIndex"}},[(!_vm.isUserVerified && _vm.isTFAEnabled())?_c('ValidationObserver',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('CustomerVerificationClaimTracking',{attrs:{"job-id":_vm.jobId}})],1)],1)],1):_vm._e(),_vm._l((_vm.claimTrackingModelList),function(claimTrackingModel,index){return _c('v-tab-item',{key:index,attrs:{"value":index}},[(_vm.isUserVerified || !_vm.isTFAEnabled())?_c('ClaimTrackingDetails',{ref:"claimTrackingDetail",refInFor:true,attrs:{"claim-tracking-details-model":claimTrackingModel,"selected-claim-index":_vm.selectedClaimIndex,"total-claims-count":_vm.claimTrackingModelList.length,"previous-latitude":_vm.engineerLocationPreviousLatitude,"previous-longitude":_vm.engineerLocationPreviousLongitude,"heading-magnetic-north":_vm.headingMagneticNorth,"show-progress-bar":_vm.showProgressBar(
                      claimTrackingModel.claimVisitComplete !== undefined
                    )}}):_vm._e()],1)})],2),(_vm.claimTrackingModelList.length && _vm.selectedClaimIndex > 0)?_c('v-btn',{staticClass:"tab-icon tab-icon--previous",style:({ color: _vm.clientTemplate.complementaryColor }),attrs:{"icon":"","text":"","label":"Move to Previous emergency","aria-label":"Move to Previous emergency"},on:{"click":function($event){_vm.selectedClaimIndex -= 1}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_back_ios")])],1):_vm._e(),_c('v-btn',{staticClass:"tab-icon tab-icon--next",style:({ color: _vm.clientTemplate.complementaryColor }),attrs:{"text":"","icon":"","dark":"","label":"Move to Next emergency","aria-label":"Move to Next emergency"},on:{"click":function($event){_vm.selectedClaimIndex += 1}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_forward_ios")])],1)],1):_c('div',{staticClass:"job-complete-notification px-5"},[_c('MessageCard',{attrs:{"message":"Your tracking link is expired!"}})],1),_c('v-tabs',{attrs:{"background-color":"grey lighten-4","dark":"","slider-color":_vm.clientTemplate.complementaryColor},model:{value:(_vm.selectedClaimIndex),callback:function ($$v) {_vm.selectedClaimIndex=$$v},expression:"selectedClaimIndex"}},[_vm._l((_vm.claimTrackingModelList),function(claimTrackingModel){return [(claimTrackingModel.claimEmergencyDetail)?_c('v-tab',{key:claimTrackingModel.id,staticClass:"grey--text",attrs:{"ripple":""}},[_c('span',[(
                      claimTrackingModel.claimEmergencyDetail.typeDescription
                    )?[_vm._v(" "+_vm._s(claimTrackingModel.claimEmergencyDetail.typeDescription)+" ")]:[_vm._v("EMERGENCY")],(
                      claimTrackingModel.claimEmergencyDetail
                        .detailDescription
                    )?_c('span',{staticClass:"tab-sub-text"},[_vm._v(" ("+_vm._s(claimTrackingModel.claimEmergencyDetail .detailDescription)+") ")]):_vm._e()],2)]):_vm._e()]})],2)],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }