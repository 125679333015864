import Environment from '@/common/environment'

import ErrorSnackBar from '@/models/generic/error-snackbar'
import SnackBar from '@/models/generic/snackbar'
import Navigation from '@/models/navigation/navigation'
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import { IGeneralState } from './interfaces/general-state'
export default class GeneralState implements IGeneralState {
  public environment: Environment = new Environment()
  public errorSnackBar: ErrorSnackBar = new ErrorSnackBar('')
  public snackBar: SnackBar = new SnackBar('')
  public navigation: Navigation = new Navigation()
  public loqateApiKey: ''
  public trackingId: ''
  public recaptchaToken: string | null = null
  public recaptchaResponse: RecaptchaResponse | null = new RecaptchaResponse()
  public isUserVerifiedSuccess = false
}
