import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { ActionTree } from 'vuex'

const actions: ActionTree<IInsurerPortalState, any> = {
  setAddressLine1({ commit }, payload: string) {
    commit('setAddressLine1', payload)
  },
  setAddressLine2({ commit }, payload: string) {
    commit('setAddressLine2', payload)
  },
  setAddressLine3({ commit }, payload: string) {
    commit('setAddressLine3', payload)
  },
  setAddressLine4({ commit }, payload: string) {
    commit('setAddressLine4', payload)
  },
  setPostcode({ commit }, payload: string) {
    commit('setPostcode', payload)
  },
}

export default actions
