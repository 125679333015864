



























import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component
export default class RecaptchaVerification extends Vue {
  $refs!: {
    recaptchaValidator: InstanceType<typeof ValidationProvider>
  }

  public async created() {
    this.verifyCustomer()
  }

  public set recaptchaToken(token: string | null) {
    this.$store.dispatch('generalModule/submitRecaptchaToken', token)
  }

  public get recaptchaToken(): string | null {
    return this.$store.getters['generalModule/recaptchaToken']
  }

  public get recaptchaResponse(): RecaptchaResponse | null {
    return this.$store.getters['generalModule/recaptchaResponse']
  }

  public get isRecaptchaValid(): boolean {
    return this.recaptchaResponse !== null && this.recaptchaResponse.success
  }

  public async retrieveRecaptchToken() {
    try {
      await this.$recaptchaLoaded()
      this.recaptchaToken = await this.$recaptcha('login')
    } catch (error) {
      this.recaptchaToken = null
    }
  }

  public async verifyCustomer() {
    if (this.$refs.recaptchaValidator) {
      this.$refs.recaptchaValidator.setErrors([])
    }

    await this.retrieveRecaptchToken()
    if (this.recaptchaToken) {
      this.$emit('verifyCustomer')
    }
  }

  // Vee validate wont trigger unless the field is clicked :(
  @Watch('recaptchaResponse', { deep: true })
  public async triggerRecaptchaValidation() {
    await this.$refs.recaptchaValidator.validate()
  }
}
