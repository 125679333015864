var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.localQA)?_c('div',{staticClass:"vulnerable-card-preview"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"vulnerable-card-preview__header"},[_vm._v(" "+_vm._s(_vm.localQA.questionText)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"id":"vulnerability-qa","error-messages":errors},on:{"change":function($event){return _vm.onSelectionChange()}},model:{value:(_vm.localQA.isHealthAffected),callback:function ($$v) {_vm.$set(_vm.localQA, "isHealthAffected", $$v)},expression:"localQA.isHealthAffected"}},[_c('v-radio',{attrs:{"label":"Yes","color":"primary","value":true}}),_c('v-radio',{attrs:{"label":"No","color":"primary","value":false}})],1)]}}],null,false,918851215)}),(_vm.localQA.isHealthAffected)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.localQA.isHealthAffected)?_c('v-select',{staticClass:"health-safety-qa",attrs:{"id":"health-safety-qa","items":_vm.getLevel,"label":"Severity level","item-text":"level","item-value":"id","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"v-select__selection",attrs:{"id":"selected-health-safety-qa","aria-haspopup":"listbox","role":"button","aria-expanded":"false","aria-label":("Level" + (data.item.level))}},[_vm._v(" "+_vm._s(data.item.level)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"id":data.item.level,"role":"text","tabindex":"0","aria-label":_vm.getSelectedItem(data.item.level)
                  ? data.item.level + 'list item selected'
                  : data.item.level}},[_vm._v(" "+_vm._s(data.item.level)+" ")])]}}],null,true),model:{value:(_vm.localQA.level),callback:function ($$v) {_vm.$set(_vm.localQA, "level", $$v)},expression:"localQA.level"}}):_vm._e()]}}],null,false,261683929)}):_vm._e(),(_vm.localQA.isHealthAffected)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(_vm.localQA.isHealthAffected)?_c('v-textarea',{attrs:{"label":"Details","error-messages":errors},model:{value:(_vm.localQA.answerDetail),callback:function ($$v) {_vm.$set(_vm.localQA, "answerDetail", $$v)},expression:"localQA.answerDetail"}}):_vm._e()]}}],null,false,933209205)}):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }