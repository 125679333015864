import { Vue } from 'vue-property-decorator'
import store from '@/store'
import ClientTemplate from '@/models/client/client-template'

// TODO: add other colours once known and update SetTheme funtion
export abstract class Theme {
  public abstract primary: string
  public abstract secondary: string
}

export class InsurerPortalTheme implements Theme {
  public primary: string
  public secondary: string

  public constructor() {
    const ct: ClientTemplate = store.getters['clientModule/clientTemplate']
    this.primary = ct.primaryColor
    this.secondary = ct.secondaryColor
  }
}

export function SetTheme(componentInstance: Vue, theme: Theme) {
  // set values
  componentInstance.$vuetify.theme.themes.light.primary = theme.primary
  componentInstance.$vuetify.theme.themes.light.secondary = theme.secondary
}
