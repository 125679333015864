import { IInsurerPortalState } from '@/vuex/states/interfaces/insurer-portal-state'
import { MutationTree } from 'vuex'

const setter =
  <K extends keyof IInsurerPortalState['insurerPortalModel']>(property: K) =>
  <T extends IInsurerPortalState['insurerPortalModel'][K]>(
    state: IInsurerPortalState,
    value: T
  ): void => {
    state.insurerPortalModel[property] = value
  }

const mutations: MutationTree<IInsurerPortalState> = {
  setAddressLine1: setter('addressLine1'),
  setAddressLine2: setter('addressLine2'),
  setAddressLine3: setter('addressLine3'),
  setAddressLine4: setter('addressLine4'),
  setPostcode: setter('postcode'),
}

export default mutations
