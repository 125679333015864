











































































import { Component, Vue } from 'vue-property-decorator'
import MessageCard from '@/components/MessageCard.vue'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ImageType } from '@/common/enums'

@Component({
  name: 'uploadInvoiceCAT',
  components: {
    ImageUpload,
    MessageCard,
  },
})
export default class UploadInvoiceCAT extends Vue {
  private isLinkValid = false
  private recordId = ''
  private catInvoiceImageType: ImageType = ImageType.CATInvoice
  private isLoading = true

  private async created() {
    this.recordId = this.$route.params.recordId
    if (this.$route.params.trackingId && this.recordId) {
      if (!this.getTrackingId) {
        this.$store.dispatch(
          'generalModule/updateTrackingId',
          this.$route.params.trackingId
        )
      }
      await this.$store
        .dispatch(
          'imageModule/submitAuthoriseCustomerToUploadReceipt',
          this.recordId
        )
        .then((isValid: boolean) => {
          this.isLoading = false
          this.isLinkValid = isValid
        })
    }
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
