









































import { Component, Vue } from 'vue-property-decorator'
import Shared from '@/common/shared'
@Component
export default class Confirmation extends Vue {
  public positiveActionText = 'Delete'
  public negativeActionText = 'Cancel'
  private dialog = false
  private item: any
  private message = ''
  private parentComponent: any
  private callbackMethodName: string
  private callbackMethodNameCancel: string

  public open(
    message: string,
    name: string,
    type: string,
    negativeActionText: string,
    positiveActionText: string,
    parentComponent: any,
    callbackMethodName: string,
    item: any,
    callbackMethodNameCancel = ''
  ) {
    this.item = item
    this.positiveActionText = positiveActionText
      ? positiveActionText
      : this.positiveActionText
    this.negativeActionText = negativeActionText
      ? negativeActionText
      : this.negativeActionText
    this.message = message
      ? message
      : 'Do you really want to delete ' +
        type +
        (name ? ' : ' + name + '?' : '?')
    this.parentComponent = parentComponent
    this.callbackMethodName = callbackMethodName
    this.dialog = true
    this.callbackMethodNameCancel = callbackMethodNameCancel
  }

  private created() {
    Shared.confirmationPopup = this
  }

  private onConfirm() {
    try {
      this.dialog = false
      if (this.parentComponent != null) {
        this.parentComponent[this.callbackMethodName](this.item)
      } else {
        this.$emit('DialogConfirm', this.item)
      }
    } catch (e) {
      // TODO: handle error
    }
  }

  private onCancel() {
    try {
      this.dialog = false
      if (this.parentComponent != null && this.callbackMethodNameCancel) {
        this.parentComponent[this.callbackMethodNameCancel](this.item)
      } else {
        this.$emit('DialogCancel', this.item)
      }
    } catch (e) {
      // TODO: handle error
    }
  }
}
