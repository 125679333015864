var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":"Customer verification"}},[(_vm.isReCaptchaEnabled)?_c('RecaptchaVerification',{ref:"recaptchaVerification",on:{"verifyCustomer":_vm.verifyCustomer}}):_vm._e(),_c('div',{attrs:{"role":"form","aria-label":"verification form"}},[_c('v-container',{staticClass:"px-2 pt-2 verification__container",attrs:{"fluid":""}},[_c('h2',{staticClass:"verification__title mb-1",style:({ color: _vm.clientTemplate.primaryColor })},[_vm._v(" Verification Code ")]),_c('v-row',{staticClass:"pa-3 ma-0",style:({ background: _vm.clientTemplate.secondaryColor }),attrs:{"wrap":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Verification code","rules":"required|numeric|length:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"label":"Verification Code","aria-required":"true","counter":"6","maxlength":"6","required":"","error-messages":errors,"disabled":_vm.isUserVerifiedSuccess},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}})]}}])})],1),(!_vm.isUserVerifiedSuccess)?_c('v-col',{staticClass:"text-right mt-1 pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"verification__resend-label",style:({
              color: _vm.clientTemplate.complementaryColor + '!important',
            })},[_vm._v(" You didn't receive a code? "),_c('a',{staticClass:"verification__resend-btn",class:_vm.showTimer || _vm.disableResendButton
                  ? 'verification__resend-btn--disabled'
                  : '',style:({
                color: _vm.clientTemplate.complementaryColor + '!important',
              }),attrs:{"disabled":_vm.showTimer,"href":"Javascript:void(0)"},on:{"click":function($event){return _vm.resendVerificationCode()}}},[_vm._v("Resend")])]),(_vm.showTimer)?_c('span',{staticClass:"verification__resend-timer"},[_c('v-icon',{staticClass:"px-1",style:({ color: _vm.clientTemplate.complementaryColor }),attrs:{"small":""}},[_vm._v("timer")]),_c('b',{style:({ color: _vm.clientTemplate.complementaryColor })},[_vm._v("00:"+_vm._s(_vm.getTimeLeft))])],1):_vm._e()]):_vm._e()],1)],1),(_vm.validateUserVerificationMessage)?_c('div',{staticClass:"px-2"},[_c('span',{staticClass:"verification__error-text",style:({ color: _vm.clientTemplate.complementaryColor }),attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.validateUserVerificationMessage)+" ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }